import { Outlet } from 'react-router-dom'

import { useCurrentUser } from '@contexts/currentUser'

const Archived = () => {
  const { currentMembership: { deactivated } } = useCurrentUser()

  return (
    <>
      <Choose>
        <When condition={!deactivated}>
          <Outlet />
        </When>

        <Otherwise>
          <div className='flex flex-col items-center justify-center h-full'>
            <h1 className='text-3xl font-bold font-heading'>Your account has been archived 🗂</h1>

            <p className='text-lg mt-5'>
              Please ask your teacher to activate your account if this is a mistake.
            </p>
          </div>
        </Otherwise>
      </Choose>
    </>
  )
}

export default Archived
