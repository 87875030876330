import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { useMutation, useQuery } from '@hooks/graphql'

const UPDATE_EDUCATOR_PROJECT_SUBMISSION_MUTATION = gql`
  mutation updateEducatorProjectSubmission($input: UpdateEducatorProjectSubmissionInput!) {
    updateEducatorProjectSubmission(input: $input) {
      educatorProjectSubmission {
        id
        status
        educatorProject {
          id
          name
          readme
          series {
            id
            name
          }
          blocks {
            blockId
            position
            ... on Text {
              __typename
              id
              content
            }
            ... on Reflection {
              __typename
              id
              question
            }
            ... on Question {
              __typename
              id
              label
              options {
                id
                label
              }
            }
            ... on FreeResponse {
              __typename
              id
              question
            }
            ... on Video {
              __typename
              id
              url
            }
            ... on Image {
              __typename
              id
              caption
              file {
                filename
                url
              }
            }
          }
          playground
          submission
          createdAt
          updatedAt
        }
        blockSubmissions {
          id
          status
          blockId
          content {
            ... on ReflectionSubmission {
              __typename
              answer
            }
            ... on QuestionSubmission {
              __typename
              answer {
                id
              }
            }
          }
        }
      }
      errors {
        message
      }
    }
  }
`

const EDUCATOR_PROJECT_SUBMISSION_QUERY = gql`
  query educatorProjectSubmission($id: ID!) {
    node(id: $id) {
      ... on EducatorProjectSubmission {
        id
        status
        score
        educatorProject {
          id
          name
          totalPossibleScore
          series {
            id
            name
            assignment {
              id
              proFeaturesEnabled
              closed
            }
          }
          blocks {
            blockId
            position
            ...on Text {
              __typename
              id
              content
            }
            ...on Reflection {
              __typename
              id
              question
            }
            ... on Question {
              __typename
              id
              label
              options {
                id
                label
                correct
              }
              markAllocation
            }
            ... on FreeResponse {
              __typename
              id
              question
              markAllocation
            }
            ... on Video {
              __typename
              id
              url
            }
            ... on Image {
              __typename
              id
              caption
              file {
                filename
                url
              }
            }
          }
          playground
          submission
          createdAt
          updatedAt
        }
        blockSubmissions {
          id
          status
          blockId
          content {
            ... on ReflectionSubmission {
              __typename
              answer
            }
            ... on FreeResponseSubmission {
              __typename
              answer
              marks
              feedback
              automarkedResult
              attachments {
                id
                filename
                url
              }
            }
            ... on QuestionSubmission {
              __typename
              answer {
                id
              }
              marks
            }
          }
        }
      }
    }
  }
`

const CLASSROOMS_QUERY = gql`
  query classrooms {
    classrooms {
      id
      name
    }
  }
`

const UPDATE_BLOCK_SUBMISSION_MUTATION = gql`
  mutation updateBlockSubmission($input: UpdateBlockSubmissionInput!) {
    updateBlockSubmission(input: $input) {
      blockSubmission {
        status
      }
      errors
    }
  }
`

const useEducatorProjectSubmission = ({ educatorProjectId }) => {
  const {
    isLoading: isUpdating,
    mutateAsync,
    data: {
      updateEducatorProjectSubmission: { educatorProjectSubmission: { id: submissionId } = {} } = {}
    } = {}
  } = useMutation({
    gqlMutation: UPDATE_EDUCATOR_PROJECT_SUBMISSION_MUTATION
  })

  const {
    isLoading,
    refetch,
    data: { node: { educatorProject: project, ...submission } = {} } = {}
  } = useQuery({
    queryKey: ['educatorProjectSubmission', submissionId],
    gqlQuery: EDUCATOR_PROJECT_SUBMISSION_QUERY,
    variables: { id: submissionId },
    enabled: !!submissionId
  })

  const updateSubmission = data => mutateAsync({ input: { educatorProjectId, ...data } })

  useEffect(() => {
    updateSubmission()
  }, [educatorProjectId])

  return { submission, project, isLoading, refetch, updateSubmission, isUpdating }
}

const useClassrooms = () => {
  const { isLoading, data: { classrooms = [] } = {} } = useQuery({
    queryKey: ['classrooms'],
    gqlQuery: CLASSROOMS_QUERY
  })

  return { classrooms, isLoading }
}

const useUpdateBlockSubmission = ({ educatorProjectSubmissionId }) => {
  const queryClient = useQueryClient()

  const { isLoading, mutate } = useMutation({
    gqlMutation: UPDATE_BLOCK_SUBMISSION_MUTATION,
    onSuccess: () => queryClient.invalidateQueries(['educatorProjectSubmission', educatorProjectSubmissionId])
  })

  const updateBlockSubmission = data => mutate({ input: { educatorProjectSubmissionId, ...data } })

  return { updateBlockSubmission, isLoading }
}

export {
  useEducatorProjectSubmission,
  useClassrooms,
  useUpdateBlockSubmission
}
