import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'

import Button from '@components/Button'
import TextEditor from '@components/TextEditor'
import Label from '@components/Label'
import { useMutation } from '@hooks/graphql'

const UPDATE_REFLECTION_BLOCK_MUTATION = gql`
  mutation updateReflectionBlock($input: UpdateReflectionBlockInput!) {
    updateReflectionBlock(input: $input) {
      reflection {
        question
      }
      errors {
        message
      }
    }
  }
`

const ReflectionQuestionForm = ({ id, question, refetch, uploadImage }) => {
  const { handleSubmit, control } = useForm({ defaultValues: { question } })

  const { mutate: updateReflectionBlock, isLoading } = useMutation({
    gqlMutation: UPDATE_REFLECTION_BLOCK_MUTATION,
    onSuccess: () => refetch()
  })

  const onSubmit = ({ question }) => {
    updateReflectionBlock({ input: { id, question } })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-full h-full flex flex-col px-5 py-5'>
      <Label labelFor='question' title='Question' />

      <TextEditor.Container className='h-full max-h-full'>
        <Controller
          name='question'
          control={control}
          render={({ field }) => (
            <TextEditor
              uploadImage={uploadImage}
              id='question'
              {...field}
            />
          )}
        />

        <TextEditor.Actions>
          <TextEditor.ToolbarToggle />
        </TextEditor.Actions>
      </TextEditor.Container>

      <Button
        className='ml-auto mt-3'
        type='submit'
        disabled={isLoading}
        label={isLoading ? 'Saving...' : 'Save'}
      />
    </form>
  )
}

export default ReflectionQuestionForm
