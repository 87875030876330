import { gql } from 'graphql-request'
import { useSearchParams } from 'react-router-dom'
import { ResponsiveContainer, BarChart, ScatterChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Scatter } from 'recharts'
import jsonToCsvExport from 'json-to-csv-export'
import { capitalize, orderBy, round } from 'lodash'
import { CloudArrowDownIcon } from '@heroicons/react/24/outline'

import { useQuery } from '@hooks/graphql'
import { formatDuration } from '@helpers/format'
import Select from '@components/Select'
import Table from '@components/Table'
import Button from '@components/Button'
import Card from '@components/Card'
import Spinner from '@components/Spinner'
import NoResults from './NoResults'

const CLASSROOM_INSIGHTS_QUERY = gql`
  query classroomInsights($classroomId: ID!, $insightableId: ID!) {
    classroomInsights(classroomId: $classroomId, insightableId: $insightableId) {
      profile {
        firstName
        lastName
      }
      totalTimeSeconds
      activeTimeSeconds
      questionsAsked
      questionsAnswered
      answerQuality
      group
      misconceptionsConcepts
    }
  }
`

const Insights = ({ id, classroomId, educatorProjects }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedLessonId = searchParams.get('lessonId') || educatorProjects?.[0]?.id || null

  const setSelectedLessonId = lessonId => {
    setSearchParams({ lessonId })
  }

  const { data: { classroomInsights: rawData = [] } = {}, isInitialLoading: isLoading } = useQuery({
    queryKey: ['classroomInsights', classroomId, selectedLessonId],
    gqlQuery: CLASSROOM_INSIGHTS_QUERY,
    variables: { classroomId, insightableId: selectedLessonId || id },
    enabled: !!selectedLessonId
  })

  const flattenData = (data) => {
    return data.map(item => {
      const flattened = {}

      Object.entries(item).forEach(([key, value]) => {
        if (typeof value !== 'object' || value === null) {
          flattened[key] = value
        } else {
          Object.entries(value).forEach(([subKey, subValue]) => {
            flattened[`${key}_${subKey}`] = subValue
          })
        }
      })

      return flattened
    })
  }

  const dataToExport = {
    data: flattenData(rawData),
    filename: 'mindjoy-export'
  }

  const scatterData = rawData.reduce((acc, student) => {
    const key = student.answerQuality
    const value = {
      y: student.questionsAsked,
      x: round(student.activeTimeSeconds / 60, 2),
      tooltipData: {
        Name: `${student.profile.firstName} ${student.profile.lastName}`,
        Questions: student.questionsAsked,
        Quality: capitalize(student.answerQuality),
        Time: formatDuration(student.activeTimeSeconds)
      }
    }

    if (!acc[key]) {
      acc[key] = []
    }

    acc[key].push(value)
    return acc
  }, {})

  const CustomTooltip = ({ active, payload, _label }) => {
    if (active && payload && payload.length) {
      return (
        <Card className='p-3'>
          {Object.entries(payload[0].payload.tooltipData || [])
            .filter(([_, value]) => value)
            .map(([key, value]) => (
              <div key={key} className='flex flex-col'>
                <span className='text-sm font-medium text-gray-500'>{key}</span>
                <span className='text-lg font-semibold text-gray-900'>{value}</span>
              </div>
            ))}

        </Card>
      )
    }

    return null
  }

  const cardData = [
    {
      title: 'Student Groups',
      type: 'cards',
      data: [
        { name: 'Beginner', value: `${rawData.filter((student) => student.group === 'beginner').length} students` },
        { name: 'Intermediate', value: `${rawData.filter((student) => student.group === 'intermediate').length} students` },
        { name: 'Advanced', value: `${rawData.filter((student) => student.group === 'advanced').length} students` }
      ]
    },
    {
      title: 'Class Averages',
      type: 'cards',
      data: [
        { name: 'Total Time', value: formatDuration((rawData.reduce((acc, student) => acc + student.totalTimeSeconds, 0) / rawData.length) / 60) },
        { name: 'Active Engagement', value: formatDuration((rawData.reduce((acc, student) => acc + student.activeTimeSeconds, 0) / rawData.length) / 60) },
        { name: 'Questions Asked', value: (rawData.reduce((acc, student) => acc + student.questionsAsked, 0) / rawData.length).toFixed(1) },
        { name: 'Questions Answered', value: (rawData.reduce((acc, student) => acc + student.questionsAnswered, 0) / rawData.length).toFixed(1) }
      ]
    },
    {
      title: 'Answer Quality Distribution',
      type: 'barChart',
      data: [
        { name: 'Poor', value: rawData.filter((student) => student.answerQuality === 'poor').length },
        { name: 'Satisfactory', value: rawData.filter((student) => student.answerQuality === 'satisfactory').length },
        { name: 'Good', value: rawData.filter((student) => student.answerQuality === 'good').length },
        { name: 'Excellent', value: rawData.filter((student) => student.answerQuality === 'excellent').length }
      ].map((item) => ({ ...item, tooltipData: { Quality: item.name, Answers: item.value } }))
    },
    {
      title: 'Active Engagement Time',
      type: 'barChart',
      labels: {
        x: 'Student',
        y: 'Active Time (mins)'
      },
      angle: {
        x: -45
      },
      data: orderBy(
        rawData.map((student) => ({
          name: student.profile.firstName,
          value: round(student.activeTimeSeconds / 60, 2),
          tooltipData: { Name: `${student.profile.firstName} ${student.profile.lastName}`, Time: formatDuration(student.activeTimeSeconds) }
        })),
        ['name'],
        ['asc']
      )
    },
    {
      title: 'Active Time vs Questions Asked',
      type: 'scatterChart',
      units: {
        x: ' min'
      },
      data: scatterData
    },
    {
      title: 'Misconceptions',
      type: 'table',
      data: orderBy(
        rawData.reduce((acc, student) => {
          if (!student.misconceptionsConcepts) return acc

          student.misconceptionsConcepts.forEach((misconception) => {
            const existingMisconception = acc.find(item => item.name === misconception)
            if (existingMisconception) {
              existingMisconception.count += 1
            } else {
              acc.push({ name: misconception, count: 1 })
            }
          })
          return acc
        }, []),
        ['count'],
        ['desc']
      )
    }
  ]

  const renderCardContent = (card) => {
    switch (card.type) {
      case 'cards':
        // Don't make dynamic classes (like grid-cols-${card.data.length}) or Tailwind will purge them. Tailwind Safelist not working for some reason, so:
        if (card.data.length === 4) {
          return (
            <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4'>
              {card.data.map((item) => (
                <div key={item.name} className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
                  <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                  <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
                </div>
              ))}
            </dl>
          )
        } else {
          return (
            <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3'>
              {card.data.map((item) => (
                <div key={item.name} className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
                  <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                  <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
                </div>
              ))}
            </dl>
          )
        }
      case 'barChart':
        return (
          <Card className='items-center p-3'>
            <div className='mt-5 h-64'>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart data={card.data} margin={{ bottom: card.labels && card.labels.x && 45 }} style={{ cursor: 'pointer' }}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis interval={0} dataKey='name' angle={card.angle && card.angle.x} textAnchor={(card.angle && card.angle.x && 'end') || 'middle'} label={{ value: card.labels && card.labels.x, offset: -40, position: 'insideBottom' }} />
                  <YAxis label={{ value: card.labels && card.labels.y, angle: -90, offset: 20, position: 'insideBottomLeft' }} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey='value' fill='#3b82f6' />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        )
      case 'scatterChart':
        return (
          <Card className='items-center p-3'>
            <div className='mt-5 h-64'>
              <ResponsiveContainer width='100%' height='100%'>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 10,
                    left: 10
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='x' type='number' unit={card.units && card.units.x} />
                  <YAxis dataKey='y' type='number' />
                  <Tooltip content={<CustomTooltip />} />
                  <Scatter data={card.data.null} fill='#64748b' />
                  <Scatter data={card.data.poor} fill='#ef4444' />
                  <Scatter data={card.data.satisfactory} fill='#eab308' />
                  <Scatter data={card.data.good} fill='#22c55e' />
                </ScatterChart>
              </ResponsiveContainer>
            </div>
          </Card>
        )
      case 'table':
        return (
          <div className='mt-5'>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header>Topic</Table.Header>
                  <Table.Header>Count</Table.Header>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <If condition={card.data.length < 1}>
                  <Table.Row>
                    <Table.Cell colSpan='2'>No misconceptions found 😀</Table.Cell>
                  </Table.Row>
                </If>

                <For each='misconception' of={card.data}>
                  <Table.Row key={misconception.name}>
                    <Table.Cell>{misconception.name}</Table.Cell>
                    <Table.Cell>{misconception.count}</Table.Cell>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className='sm:mx-5 my-3 space-y-4'>
      <Card className='flex items-center p-3'>
        <div className='flex space-x-4'>
          <Select
            label='Lesson'
            value={selectedLessonId}
            onChange={e => setSelectedLessonId(e.target.value)}
          >
            <Select.Option key='' value=''>Select a lesson</Select.Option>
            <For each='educatorProject' of={educatorProjects}>
              <Select.Option key={educatorProject.id} value={educatorProject.id}>{educatorProject.name}</Select.Option>
            </For>
          </Select>
        </div>

        <If condition={rawData.length > 0}>
          <div className='ml-auto'>
            <Button
              onClick={() => jsonToCsvExport(dataToExport)}
              theme='secondary'
              variant='outlined'
              className='flex items-center'
              label={<span className='flex gap-2 items-center'>CSV<CloudArrowDownIcon className='h-6 w-6' /></span>}
            />
          </div>
        </If>
      </Card>

      <Choose>
        <When condition={isLoading}>
          <Spinner className='flex items-center justify-center w-full mt-24' />
        </When>

        <When condition={rawData.length < 1}>
          <NoResults lessonSelected={!!selectedLessonId} />
        </When>

        <Otherwise>
          <div className='mx-5 sm:mx-0 my-4 space-y-8 pb-5'>
            <If condition={rawData.length > 0}>
              {cardData.map((card, index) => (
                <div key={index} class='space-y-1'>
                  <h3 className='mx-3 sm-mx-0 text-lg font-semibold text-gray-900'>{card.title}</h3>
                  {renderCardContent(card)}
                </div>
              ))}
            </If>
          </div>
        </Otherwise>
      </Choose>
    </div>
  )
}

export default Insights
