import { useQuery as useReactQuery, useMutation as useReactMutation, useInfiniteQuery as useReactInfiniteQuery } from '@tanstack/react-query'
import { request } from '@helpers/graphql'

import { useCurrentUser } from '@contexts/currentUser'

const useQuery = ({ gqlQuery, variables, headers = {}, ...options }) => {
  const { currentMembership: { organization: { id: organizationId } = {} } = {} } = useCurrentUser()

  const wrappedQueryFn = () => {
    const mergedHeaders = organizationId ? { ...headers, 'X-Organization-Id': organizationId } : headers
    return request(gqlQuery, variables, mergedHeaders)
  }

  return useReactQuery({
    ...options,
    queryFn: wrappedQueryFn
  })
}

const useMutation = ({ gqlMutation, headers = {}, variables: defaultVariables = {}, ...options }) => {
  const { currentMembership: { organization: { id: organizationId } = {} } = {} } = useCurrentUser()

  const wrappedMutationFn = (variables = {}) => {
    const mergedHeaders = organizationId ? { ...headers, 'X-Organization-Id': organizationId } : headers
    const mergedVariables = { ...defaultVariables, ...variables }
    return request(gqlMutation, mergedVariables, mergedHeaders)
  }

  return useReactMutation({
    ...options,
    mutationFn: wrappedMutationFn
  })
}

export { useQuery, useMutation }
