import { gql } from 'graphql-request'

import { useMutation } from '@hooks/graphql'
import OverflowMenu from '@components/OverflowMenu'

const UPDATE_AUTHORIZATION_ROLE_MUTATION = gql`
  mutation updateMembershipRole($input: UpdateRoleInput!) {
    updateMembershipRole(input: $input) {
      membership {
        role
      }
      errors {
        message
      }
    }
  }
`

const EducatorListActions = ({ id, email, fullName, role, refetch }) => {
  const educatorNameOrEmail = fullName.trim() === '' ? email : fullName

  const { mutate: updateEducatorRole } = useMutation({
    gqlMutation: UPDATE_AUTHORIZATION_ROLE_MUTATION,
    onSuccess: () => {
      refetch()
    }
  })

  const handleRoleChange = (id, newRole) => {
    updateEducatorRole({
      input: {
        profileId: id,
        role: newRole
      }
    })
  }

  return (
    <OverflowMenu orientation='horizontal'>
      <Choose>
        <When condition={role === 'ADMIN'}>
          <OverflowMenu.Item>
            <button
              onClick={() => handleRoleChange(id, 'EDUCATOR')}
              className='text-md w-full text-left'
            >
              Make {educatorNameOrEmail} an educator
            </button>
          </OverflowMenu.Item>
        </When>

        <Otherwise>
          <OverflowMenu.Item>
            <button
              onClick={() => handleRoleChange(id, 'ADMIN')}
              className='text-md w-full text-left'
            >
              Make {educatorNameOrEmail} an admin
            </button>
          </OverflowMenu.Item>
        </Otherwise>
      </Choose>
    </OverflowMenu>
  )
}

export default EducatorListActions
