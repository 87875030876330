import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'

import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import LinkButton from '@components/LinkButton'
import RadioGroup from '@components/RadioGroup'
import SecureMarkdown from '@components/SecureMarkdown'
import Tooltip from '@components/Tooltip'

const CREATE_QUESTION_SUBMISSION_MUTATION = gql`
  mutation createQuestionSubmission($input: CreateQuestionSubmissionInput!) {
    createQuestionSubmission(input: $input) {
      errors {
        message
      }
      blockSubmission {
        content {
          ... on QuestionSubmission {
            question {
              id
              label
            }
            answer {
              id
              label
            }
          }
        }
      }
    }
  }
`

const QuestionBlock = ({
  blockId,
  submissionId,
  moveToNextBlock,
  moveToPreviousBlock,
  isFirstBlock,
  isLastBlock,
  openSubmitModal,
  isSubmissionClosed,
  submissionStatus,
  question,
  options,
  answerId,
  marks,
  markAllocation
}) => {
  const queryClient = useQueryClient()

  const { mutate: createQuestionSubmission } = useMutation({
    gqlMutation: CREATE_QUESTION_SUBMISSION_MUTATION,
    onSuccess: () => { queryClient.invalidateQueries('educatorProjectSubmission') }
  })

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: { answerId }
  })

  const submit = data => {
    if (submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed) {
      moveToNextBlock()
    } else if (isLastBlock) {
      createQuestionSubmission({ input: { blockId, educatorProjectSubmissionId: submissionId, ...data } })
      openSubmitModal()
    } else {
      createQuestionSubmission({ input: { blockId, educatorProjectSubmissionId: submissionId, ...data } })
      moveToNextBlock()
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(submit)}
        className='flex flex-col mt-5'
      >
        <SecureMarkdown className='mb-5' content={question} />

        <Choose>
          <When condition={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED'}>
            <ul className='flex flex-col gap-2'>
              <For each='option' of={options}>
                <li
                  className={twMerge(
                    'flex border border-gray-300 p-3 rounded-lg',
                    answerId === option.id && 'border-purple-600 bg-purple-100',
                    submissionStatus === 'RETURNED' && answerId === option.id && (
                      option.correct
                        ? 'bg-green-100 border-green-600'
                        : 'bg-red-100 border-red-600'
                    )
                  )}
                  key={option.id}
                >
                  <SecureMarkdown content={option.label} />

                  <If condition={submissionStatus === 'RETURNED' && answerId === option.id}>
                    <Choose>
                      <When condition={option.correct}>
                        <CheckIcon className='ml-auto w-6 h-6 text-green-600' />
                      </When>

                      <Otherwise>
                        <XMarkIcon className='ml-auto w-6 h-6 text-red-600' />
                      </Otherwise>
                    </Choose>
                  </If>
                </li>
              </For>
            </ul>

            <If condition={submissionStatus === 'RETURNED'}>
              <div className='w-fit bg-gray-200 px-2 py-1 rounded-lg leading-tight mt-3'>
                <span className='text-lg font-semibold mb-1'>{marks} / {markAllocation}</span>
              </div>
            </If>
          </When>

          <Otherwise>
            <Controller
              name='answerId'
              control={control}
              rules={{ required: 'Choose an option to move to the next section' }}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  disabled={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed}
                  theme='secondary'
                >
                  <For each='option' of={options}>
                    <RadioGroup.Option
                      className='bg-white'
                      label={<SecureMarkdown content={option.label} />}
                      value={option.id}
                    />
                  </For>
                </RadioGroup>
              )}
            />

            <If condition={errors.answerId}>
              <p className='text-sm mt-2 text-red-500 font-semibold' role='alert'>{errors.answerId.message}</p>
            </If>

          </Otherwise>
        </Choose>

        <div className='flex flex-row items-center justify-between mt-3'>
          <LinkButton
            type='button'
            disabled={isFirstBlock}
            onClick={moveToPreviousBlock}
          >
            back
          </LinkButton>

          <Choose>
            <When condition={isLastBlock}>
              <Button
                type='submit'
                id='submit-button'
                className='self-end'
                disabled={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed}
                label={(submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED') ? 'Submitted' : 'Submit'}
              />

              <If condition={isSubmissionClosed}>
                <Tooltip anchorSelect='#submit-button' delayShow={200}>
                  Submissions are closed for this pathway
                </Tooltip>
              </If>
            </When>

            <Otherwise>
              <Button
                type='submit'
                className='self-end'
                label='Next'
              />
            </Otherwise>
          </Choose>
        </div>
      </form>
    </>
  )
}

export default QuestionBlock
