import { useForm } from 'react-hook-form'
import { gql } from 'graphql-request'
import { CheckIcon } from '@heroicons/react/24/outline'

import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import Checkbox from '@components/Checkbox'

const UPDATE_EDUCATOR_PROFILE_MUTATION = gql`
  mutation updateProfile($updateProfileInput: UpdateProfileInput!) {
    updateProfile(input: $updateProfileInput) {
      profile {
        subscribedToEmailMarketing
      }
      errors {
        message
      }
    }
  }
`

const EmailPreferences = ({ subscribedToEmailMarketing, refetch }) => {
  const { mutate: updateEducatorProfile, isLoading: isUpdating, isSuccess } = useMutation({
    gqlMutation: UPDATE_EDUCATOR_PROFILE_MUTATION,
    onSuccess: () => refetch()
  })

  const { register, handleSubmit } = useForm({ mode: 'onTouched' })

  return (
    <Card className='p-5'>
      <h2 className='text-2xl font-bold font-heading'>Email preferences</h2>

      <form className='flex flex-col space-y-4 mt-5' onSubmit={handleSubmit(data => updateEducatorProfile({ updateProfileInput: data }))}>
        <Checkbox
          id='subscribed-to-email-marketing'
          label='Let me know about product updates and events'
          defaultChecked={subscribedToEmailMarketing}
          {...register('subscribedToEmailMarketing')}
        />

        <div className='flex flex-row justify-end items-center'>
          <If condition={isSuccess}>
            <CheckIcon className='h-6 w-6 text-green-700 mr-3' />
          </If>

          <Button
            className='w-fit'
            type='submit'
            disabled={!!isUpdating}
            label={isUpdating ? 'Saving...' : 'Save'}
          />
        </div>
      </form>
    </Card>
  )
}

export default EmailPreferences
