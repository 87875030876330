import { gql } from 'graphql-request'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import Modal from '@components/Modal'

const ARCHIVE_CLASSROOM_MUTATION = gql`
  mutation archiveClassroom($input: ArchiveClassroomInput!) {
    archiveClassroom(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const ArchiveClassroomModal = ({
  closeModal,
  isOpen,
  classroomId
}) => {
  const navigate = useNavigate()
  const { mutate: archiveClassroom, isLoading: isArchiving } = useMutation({
    gqlMutation: ARCHIVE_CLASSROOM_MUTATION,
    variables: { input: { classroomId } },
    onSuccess: () => navigate('/educators/classrooms')
  })

  return (
    <Modal
      size='sm'
      isOpen={isOpen}
      onClose={closeModal}
    >
      <h3 className='font-heading text-2xl font-bold mb-3'>Archive classroom</h3>
      <p className='mb-3'>
        Are you sure you'd like to archive this classroom?
      </p>

      <div className='flex justify-end'>
        <Button
          onClick={() => archiveClassroom()}
          disabled={isArchiving}
          label={isArchiving ? 'Archiving...' : 'Archive classroom'}
          className='w-fit mt-3 self-end'
        />
      </div>
    </Modal>
  )
}

export default ArchiveClassroomModal
