import { useState } from 'react'
import { Link } from 'react-router-dom'
import { gql } from 'graphql-request'

import { useQuery } from '@hooks/graphql'
import { request } from '@helpers/graphql'
import Spinner from '@components/Spinner'
import Pagination from '@components/Pagination'

import SeriesCard from '../../components/SeriesCard'

const PUBLIC_SERIES_QUERY = gql`
  query publicSeries($page: Int) {
    publicSeries(page: $page, perPage: 6) {
      nodes {
        id
        name
        description
        coverImage {
          url
        }
        educatorName
        organizationName
        educatorProjects {
          id
          name
        }
      }
      pagesCount
      nodesCount
    }
  }
`

const PublicSeries = () => {
  const [page, setPage] = useState(1)

  const { isLoading, data: { publicSeries: { nodes: series = [], nodesCount, pagesCount } = {} } = {} } = useQuery({
    queryKey: ['publicSeries', page],
    gqlQuery: PUBLIC_SERIES_QUERY,
    variables: { page },
    keepPreviousData: true
  })

  if (isLoading) return <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />

  return (
    <>
      <div div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5'>
        <For each='series' of={series}>
          <Link key={series.id} to={`${series.id}`}>
            <SeriesCard
              id={series.id}
              name={series.name}
              description={series.description}
              coverImageUrl={series.coverImage?.url}
              educatorName={series.educatorName}
              organizationName={series.organizationName}
              projectCount={series.educatorProjects?.length}
              truncateDescription
            />
          </Link>
        </For>
      </div>

      <Pagination page={page} pagesCount={pagesCount} setPage={setPage} />
    </>
  )
}

export default PublicSeries
