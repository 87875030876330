import {
  AcademicCapIcon,
  CalendarDaysIcon,
  ChatBubbleLeftEllipsisIcon,
  UserPlusIcon,
  SparklesIcon,
  BookOpenIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'

import { useLocalStorage } from '@hooks/localStorage'
import { useCurrentUser } from '@contexts/currentUser'
import TechnicalDifficultiesBanner from './TechnicalDifficultiesBanner'

import Navbar from './Navbar'
import UpgradeBanner from '../educators/components/UpgradeBanner'
import OnboardingChecklist from '../educators/components/OnboardingChecklist'
import MembershipPicker from './MembershipPicker'

const EducatorNav = ({ children }) => {
  const { currentMembership: { organization: { features = [] } = {} } = {} } = useCurrentUser()

  const [pathwaysNoticeDismissed, setPathwaysNoticeDismissed] = useLocalStorage('pathwaysNoticeDismissed', false)

  const legacyFeaturesEnabled = features.includes('legacy_features')
  const technicalDifficultiesBannerEnabled = features.includes('technical_difficulties_banner')
  const educatorCreatorsEnabled = features.includes('educator_creators')

  const navigation = [
    { name: 'Tutors', href: '/educators/tutors', icon: ChatBubbleLeftEllipsisIcon },
    ...educatorCreatorsEnabled ? [{ name: 'Creators', href: '/educators/creators', icon: SparklesIcon }] : [],
    ...legacyFeaturesEnabled ? [{ name: 'Pathways', href: '/educators/courses', icon: BookOpenIcon }] : [],
    { name: 'Classrooms', href: '/educators/classrooms', icon: AcademicCapIcon },
    { name: 'Workshops', href: '/educators/workshops', icon: CalendarDaysIcon },
    { name: 'Invite educators', href: '/educators/organization/educators', icon: UserPlusIcon }
  ]

  return (
    <Navbar
      membershipPicker={<MembershipPicker />}
      navigation={navigation}
      message={
        <>
          <If condition={technicalDifficultiesBannerEnabled}>
            <TechnicalDifficultiesBanner />
          </If>

          <If condition={!pathwaysNoticeDismissed}>
            <div className='bg-blue-100 p-3 rounded-md mb-3 animate-[appear_0.3s_ease-in]'>
              <div className='flex items-center justify-between text-base font-semibold leading-none mb-1'>
                Pathways

                <button
                  className='rounded-full items-center'
                  onClick={() => setPathwaysNoticeDismissed(true)}
                >
                  <XMarkIcon className='size-5' />
                </button>
              </div>
              <p className='text-sm text-gray-700 leading-none'>
                We've renamed Courses to Pathways. Find out more&nbsp;
                {/* TODO(Ethan): Add full link to docs */}
                <a href='https://coda.io/d/_dazL7wOvRR3/Frequently-Asked-Questions_suRhK' target='_blank' rel='noopener noreferrer' className='underline'>here</a>.
              </p>
            </div>
          </If>

          <OnboardingChecklist />

          <UpgradeBanner />
        </>
      }
    >
      {children}
    </Navbar>
  )
}

export default EducatorNav
