import { gql } from 'graphql-request'

import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import Modal from '@components/Modal'

const DELETE_CHAT_MUTATION = gql`
  mutation deleteChat($input: DeleteChatInput!) {
    deleteChat(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const DeleteChatModal = ({
  closeModal,
  isOpen,
  chatId,
  refetch,
  selectedChatId,
  setSelectedChatId
}) => {
  const { mutate: deleteChat, isLoading: isDeleting } = useMutation({
    gqlMutation: DELETE_CHAT_MUTATION,
    variables: { input: { chatId } },
    onSuccess: () => {
      if (selectedChatId === chatId) setSelectedChatId(null)

      closeModal()
      refetch()
    }
  })

  return (
    <Modal
      size='sm'
      isOpen={isOpen}
      onClose={closeModal}
    >
      <h3 className='font-heading text-2xl font-bold mb-3'>Delete chat</h3>
      <p className='mb-3'>Are you sure you'd like to delete this chat? This can't be undone.</p>

      <div className='flex justify-end'>
        <Button
          onClick={() => deleteChat()}
          disabled={isDeleting}
          theme='error'
          label={isDeleting ? 'Deleting...' : 'Delete'}
          className='w-fit mt-3 self-end'
        />
      </div>
    </Modal>
  )
}

export default DeleteChatModal
