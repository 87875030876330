import { useForm, Controller } from 'react-hook-form'
import { gql } from 'graphql-request'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import { startCase } from 'lodash'

import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import TextInput from '@components/TextInput'
import TextArea from '@components/TextArea'
import Listbox from '@components/Listbox'
import Label from '@components/Label'
import Collapsible from '@components/Collapsible'

import CoverImage from './CoverImage'

const UDPATE_SERIES_MUTATION = gql`
  mutation updateSeries($input: UpdateSeriesInput!) {
    updateSeries(input: $input) {
      series {
        id
        name
        description
        notes
        createdAt
        visibility
      }
    }
  }
`

const Settings = ({ id, refetch, name, description, visibility, imageUrl, notes }) => {
  const { register, handleSubmit, formState: { errors }, control } = useForm({
    mode: 'onTouched',
    defaultValues: {
      name,
      description,
      notes,
      visibility
    }
  })

  const { mutate: updateSeries, isLoading } = useMutation({
    gqlMutation: UDPATE_SERIES_MUTATION,
    onSuccess: () => refetch()
  })

  const onSubmit = data => updateSeries({ input: { seriesId: id, ...data } })

  return (
    <div className='sm:flex-row flex-col flex p-5 gap-5'>
      <CoverImage
        seriesId={id}
        refetch={refetch}
        imageUrl={imageUrl}
      />

      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col grow'
      >
        <div className='mb-3'>
          <TextInput
            id='name'
            label='Name'
            {...register('name', { required: 'Name is required' })}
          />
          <If condition={errors.name}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.name.message}</p>
          </If>
        </div>

        <div className='mb-3'>
          <TextArea
            id='description'
            label='Description'
            required={false}
            {...register('description')}
          />
        </div>

        <div className='mb-5'>
          <Label labelFor='visbility'>Visibility</Label>
          <Controller
            name='visibility'
            control={control}
            render={({ field }) => (
              <Listbox
                id='visibility'
                className='w-[350px]'
                {...field}
                theme='secondary'
                placeholder='Select visibility'
                label={field.value ? startCase(field.value.toLowerCase()) : 'Select visibility'}
              >

                <Listbox.Option
                  value='PRIVATE'
                  label={
                    <div className='flex mt-1'>
                      <LockClosedIcon className='h-6 w-6 mr-3 shrink-0' />
                      <div>
                        <p className='leading-none'>Private</p>
                        <small className='leading-none'>Only available to you</small>
                      </div>
                    </div>
                  }
                />
              </Listbox>
            )}
          />
        </div>

        <Collapsible label='Advanced settings'>
          <div className='my-3'>
            <TextArea
              id='notes'
              label='Notes'
              required={false}
              {...register('notes')}
            />
            <small>Notes are not visible to students</small>
          </div>
        </Collapsible>

        <Button
          className='ml-auto w-fit'
          disabled={isLoading}
          type='submit'
          label={isLoading ? 'Saving...' : 'Save'}
        />
      </form>
    </div>
  )
}

export default Settings
