import { useState } from 'react'
import { Link, useSearchParams, useLocation } from 'react-router-dom'
import { gql } from 'graphql-request'

import { useQuery } from '@hooks/graphql'
import { formatTimestamp } from '@helpers/format'
import PageHeading from '@components/PageHeading'
import Table from '@components/Table'
import Pagination from '@components/Pagination'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'

import NoResults from './NoResults'

const ORGANIZATIONS_QUERY = gql`
  query organizations($page: Int!, $search: String) {
    organizations(page: $page, perPage: 10, search: $search) {
      nodes {
        id
        name
        createdAt
      }
      pagesCount
    }
  }
`

const OrganizationList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const page = Number(searchParams.get('page')) || 1
  const search = searchParams.get('search') || ''
  const [searchTerm, setSearchTerm] = useState(search)

  const { data: { organizations: { nodes: organizations = [], pagesCount } = {} } = {}, isLoading } = useQuery({
    queryKey: ['organizations', page, search],
    gqlQuery: ORGANIZATIONS_QUERY,
    variables: { page, search }
  })

  const updateParams = newParams => {
    const params = {
      page,
      search,
      ...newParams
    }

    if (params.page === 1) delete params.page
    if (params.search === '') delete params.search

    setSearchParams(params)
  }

  const clearFilters = () => {
    setSearchTerm('')
    setSearchParams({})
  }

  const clearSearch = () => {
    setSearchTerm('')
    updateParams({ search: '', page: 1 })
  }

  return (
    <>
      <PageHeading title='Organizations' />

      <div className='m-5'>
        <SearchInput
          className='mb-5'
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
          onSearch={() => updateParams({ search: searchTerm, page: 1 })}
          onClear={clearSearch}
        />

        <Choose>
          <When condition={isLoading}>
            <Spinner className='flex justify-center mt-64' />
          </When>

          <When condition={organizations.length < 1}>
            <NoResults clearFilters={clearFilters} />
          </When>

          <Otherwise>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header width='60%'>Name</Table.Header>
                  <Table.Header width='40%'>Created at</Table.Header>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <For each='organization' of={organizations}>

                  <Table.Row key={organization.id}>

                    <Table.Cell>
                      <Link
                        className='block'
                        to={`${organization.id}`}
                        state={{ previousListParams: location.search }}
                      >
                        {organization.name}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link
                        className='block'
                        to={`${organization.id}`}
                        state={{ previousListParams: location.search }}
                      >
                        {formatTimestamp(organization.createdAt)}
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>

            <Pagination page={page} pagesCount={pagesCount} setPage={page => updateParams({ page })} />
          </Otherwise>
        </Choose>
      </div>
    </>
  )
}

export default OrganizationList
