import { gql } from 'graphql-request'
import { ArrowLongRightIcon } from '@heroicons/react/20/solid'

import { useMutation } from '@hooks/graphql'
import Card from '@components/Card'

const DISCONNECT_LEARNING_MANAGEMENT_SYSTEM_INTEGRATION = gql`
  mutation disconnectLearningManagementSystems($input: DisconnectLearningManagementSystemsInput!) {
    disconnectLearningManagementSystems(input: $input) {
      profile {
        googleClassroomIntegrationEnabled
        microsoftTeamsIntegrationEnabled
      }
    }
  }
`

const cookies = document.cookie.split('; ')
const csrfToken = cookies?.find(cookie => cookie.startsWith('CSRF-TOKEN='))?.split('=')[1]

const Integrations = ({ googleClassroomIntegrationEnabled, microsoftTeamsIntegrationEnabled, refetch }) => {
  const { mutate: disconnect, isLoading: isUpdating } = useMutation({
    gqlMutation: DISCONNECT_LEARNING_MANAGEMENT_SYSTEM_INTEGRATION,
    variables: { input: {} },
    onSuccess: () => refetch()
  })

  return (
    <Card className='p-5'>
      <h2 className='text-2xl font-bold font-heading'>Integrations</h2>

      <Choose>
        <When condition={isUpdating}>
          <div className='w-[200px] h-10 mt-5 bg-gray-200 rounded-md animate-pulse' />
        </When>

        <When condition={googleClassroomIntegrationEnabled}>
          <button
            onClick={() => disconnect()}
            className='w-fit mt-5 flex items-center rounded-md px-3.5 py-2.5 text-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-red-600 hover:bg-gray-50 focus-visible:outline-red-600'
          >
            <img
              className='w-5 mr-5'
              src='/google-classroom-logo.png'
            />

            <span>Disconnect Google Classroom</span>
          </button>
        </When>

        <When condition={microsoftTeamsIntegrationEnabled}>
          <button
            onClick={() => disconnect()}
            className='w-fit mt-5 flex items-center rounded-md px-3.5 py-2.5 text-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-red-600 hover:bg-gray-50 focus-visible:outline-red-600'
          >
            <img
              className='w-5 mr-5'
              src='/microsoft-logo.svg'
            />

            <span>Disconnect Microsoft Teams for Education</span>
          </button>
        </When>

        <Otherwise>
          <form method='post' action={`${import.meta.env.VITE_API_URL}/users/auth/google_classroom_integration`}>
            <input type='hidden' name='authenticity_token' value={csrfToken} />
            <button
              type='submit'
              className='w-fit mt-5 flex items-center rounded-md px-3.5 py-2.5 text-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600'
            >
              <img
                className='w-5 mr-5'
                src='/google-classroom-logo.png'
              />

              <span>Connect Google Classroom</span>

              <ArrowLongRightIcon className='ml-3 w-6' />
            </button>

            <p className='mt-2 text-sm'>Mindjoy's use of information received from Google APIs will adhere to the <a className='text-blue-500 hover:cursor' href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
          </form>

          <form
            method='post'
            action={`${import.meta.env.VITE_API_URL}/users/auth/microsoft_teams_integration`}
          >
            <input type='hidden' name='authenticity_token' value={csrfToken} />

            <button
              type='submit'
              className='w-fit mt-5 flex items-center rounded-md px-3.5 py-2.5 text-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600'
            >
              <img
                className='w-5 mr-5'
                src='/microsoft-logo.svg'
              />

              <span>Connect Microsoft Teams for Education</span>

              <ArrowLongRightIcon className='ml-3 w-6' />
            </button>
          </form>
        </Otherwise>
      </Choose>
    </Card>
  )
}

export default Integrations
