import { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { gql } from 'graphql-request'

import { useQuery } from '@hooks/graphql'
import Spinner from '@components/Spinner'
import PageHeading from '@components/PageHeading'
import Pagination from '@components/Pagination'
import CourseCard from '@components/CourseCard'
import TabNav from '@components/TabNav'

import NoResults from './NoResults'

const ASSIGNED_SERIES_QUERY = gql`
  query assignedSeries($page: Int, $closed: Boolean) {
    assignedSeries(page: $page, perPage: 6, closed: $closed) {
      nodes {
        id
        name
        description
        createdAt
        coverImage {
          url
        }
        educatorProjects {
          id
          name
        }
        educatorName
        organizationName
      }
      pagesCount
      nodesCount
    }
  }
`

const CoursesList = ({ pageHeading }) => {
  const [page, setPage] = useState(1)
  const [searchParams] = useSearchParams()
  const status = searchParams.get('status') || 'open'

  const { isLoading, data: { assignedSeries: { nodes: series = [], pagesCount } = {} } = {} } = useQuery({
    queryKey: ['assignedEducatorProjects', page, status],
    gqlQuery: ASSIGNED_SERIES_QUERY,
    variables: { page, closed: status === 'closed' },
    keepPreviousData: true
  })

  return (
    <>
      <PageHeading title={pageHeading || 'Pathways'} />
      <TabNav>
        <TabNav.Link
          to='/students/courses'
          end
          label='Assigned pathways'
          isActive={location => location.search === ''}
        />
        <TabNav.Link
          to='/students/courses/?status=closed'
          label='Past pathways'
          isActive={location => location.search === '?status=closed'}
        />
      </TabNav>

      <Choose>
        <When condition={isLoading}>
          <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />
        </When>

        <When condition={series.length < 1}>
          <NoResults status={status} />
        </When>

        <Otherwise>
          <div className='flex flex-row flex-wrap px-5 pt-5 md:grid md:grid-cols-3 gap-5'>
            <For index='index' each='s' of={series}>
              <Link className='w-full' to={s.id} key={s.id}>
                <CourseCard
                  name={s.name}
                  description={s.description}
                  imageUrl={s.coverImage?.url}
                />
              </Link>
            </For>
          </div>

          <Pagination page={page} pagesCount={pagesCount} setPage={setPage} />
        </Otherwise>
      </Choose>
    </>
  )
}

export default CoursesList
