import { useState } from 'react'
import { gql } from 'graphql-request'
import { useNavigate } from 'react-router-dom'

import { useQuery } from '@hooks/graphql'
import EducatorDetails from './EducatorDetails'
import OrganizationDetails from './OrganizationDetails'
import Skeleton from './Skeleton'

const ONBOARDING_QUERY = gql`
  query onboarding {
    organization {
      id
      name
      organizationType
    }

    me {
      newProfile {
        firstName
        lastName
        organizationRole
        phoneCountryCode
        phoneNumber
        locationCountryCode
        marketingSource
        subjects {
          id
          name
        }
        onboardingCompleted
      }
    }
  }
`

const Onboarding = () => {
  const navigate = useNavigate()
  const [step, setStep] = useState(null)
  const nextStep = () => { setStep(step + 1) }

  const {
    data: {
      organization = {},
      me: { newProfile: profile = {} } = {}
    } = {},
    isLoading
  } = useQuery({
    queryKey: ['onboardingData'],
    gqlQuery: ONBOARDING_QUERY,
    onSuccess: data => {
      // Onboarding already completed
      // TODO(workspaces_migration): change this to "profile" once BE has been updated
      if (data.me.newProfile.onboardingCompleted) {
        navigate('/educators')
      }
      // User already has an organization with a name
      else if (data.organization.name) {
        setStep(2)
      } else {
        setStep(1)
      }
    }
  })

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton />
      </When>

      <When condition={step === 1}>
        <OrganizationDetails
          organizationId={organization.id}
          organizationName={organization.name}
          organizationType={organization.organizationType}
          nextStep={nextStep}
        />
      </When>

      <When condition={step === 2}>
        <EducatorDetails
          firstName={profile.firstName}
          lastName={profile.lastName}
          organizationRole={profile.organizationRole}
          subjects={profile.subjects}
          phoneCountryCode={profile.phoneCountryCode}
          phoneNumber={profile.phoneNumber}
          locationCountryCode={profile.locationCountryCode}
          marketingSource={profile.marketingSource}
          nextStep={nextStep}
        />
      </When>
    </Choose>
  )
}

export default Onboarding
