import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { SparklesIcon } from '@heroicons/react/24/outline'

import { useMutation } from '@hooks/graphql'
import CircleSpinner from '@components/CircleSpinner'
import TextEditor from '@components/TextEditor'
import Button from '@components/Button'
import LinkButton from '@components/LinkButton'
import SecureMarkdown from '@components/SecureMarkdown'
import Tooltip from '@components/Tooltip'
import Image from '@components/Image'

const CREATE_FREE_RESPONSE_SUBMISSION_MUTATION = gql`
  mutation CreateFreeResponseSubmission($input: CreateFreeResponseSubmissionInput!) {
    createFreeResponseSubmission(input: $input) {
      blockSubmission {
        content {
          ... on FreeResponseSubmission {
            answer
            attachments {
              id
              filename
              url
            }
          }
        }
      }

      errors {
        message
      }

    }
  }
`

const UPLOAD_ATTACHMENTS_MUTATION = gql`
  mutation uploadEducatorProjectAttachments($input: UploadEducatorProjectAttachmentsInput!) {
    uploadEducatorProjectAttachments(input: $input) {
      attachments {
        id
        url
        filename
      }
      errors {
        message
      }
    }
  }
`

const FreeResponseBlock = ({
  blockId,
  submissionId,
  question,
  moveToNextBlock,
  moveToPreviousBlock,
  isFirstBlock,
  isLastBlock,
  openSubmitModal,
  isSubmissionClosed,
  submissionStatus,
  answer,
  marks,
  markAllocation,
  feedback,
  automarkedResult,
  attachments = []
}) => {
  const queryClient = useQueryClient()

  const { mutate: createFreeResponseSubmission } = useMutation({
    gqlMutation: CREATE_FREE_RESPONSE_SUBMISSION_MUTATION,
    onSuccess: () => { queryClient.invalidateQueries('educatorProjectSubmission') }
  })

  const { mutateAsync: uploadAttachments, isLoading: isUploading } = useMutation({
    gqlMutation: UPLOAD_ATTACHMENTS_MUTATION
  })

  const { handleSubmit, formState: { errors }, control, setError, clearErrors } = useForm({
    mode: 'onTouched',
    defaultValues: { answer, attachments }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments'
  })

  const submit = ({ answer, attachments = [] }) => {
    const attachmentIds = attachments.map(attachment => attachment.id)

    if (submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed) {
      moveToNextBlock()
    } else if (isLastBlock) {
      createFreeResponseSubmission({ input: { blockId, educatorProjectSubmissionId: submissionId, answer, attachmentIds } })
      openSubmitModal()
    } else {
      createFreeResponseSubmission({ input: { blockId, educatorProjectSubmissionId: submissionId, answer, attachmentIds } })
      moveToNextBlock()
    }
  }

  const handleFilesAttached = async event => {
    clearErrors()

    const files = Array.from(event.target.files)

    // Check that files are indeed images.
    // File pickers can allow selecting non-image files on some devices.
    if (files.some(file => !file.type.startsWith('image/'))) {
      setError('attachments', { message: 'Files must be images.' })

      return
    }

    // File size limit
    if (files.some(file => file.size > 10000000)) { // 10mb limit
      setError('attachments', { message: 'Files must be less than 10MB in size.' })

      return
    }

    // File count limit
    if (files.length > 3) {
      setError('attachments', { message: 'You can only upload up to 3 files at a time.' })

      return
    }

    const { uploadEducatorProjectAttachments: { attachments = [], errors = [] } } = await uploadAttachments({ input: { attachments: files } })

    if (errors.length > 0) {
      setError('attachments', { message: errors[0].message })
    } else {
      append(attachments)
    }
  }

  return (
    <form
      onSubmit={handleSubmit(submit)}
    >
      <SecureMarkdown className='mb-3' content={question} />

      <Choose>
        <When condition={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED'}>
          <div className='bg-gray-200 p-3 rounded-lg mt-5'>
            <SecureMarkdown content={answer} />

            <If condition={attachments.length > 0}>
              <For each='attachment' of={attachments}>
                <Image
                  className='object-contain h-[200px] rounded-md'
                  src={attachment.url}
                  placeholder={<div className='rounded-md h-[200px] w-1/3 bg-gray-200 animate-pulse mt-3' />}
                />
              </For>
            </If>
          </div>

          <If condition={submissionStatus === 'RETURNED'}>
            <div className='w-fit bg-gray-200 px-2 py-1 rounded-lg leading-tight mt-3'>
              <span className='text-lg font-semibold mb-1'>{marks} / {markAllocation}</span>
            </div>

            <If condition={feedback}>
              <div className='flex flex-col  bg-purple-100 p-3 rounded-lg leading-tight mt-3'>
                <If condition={automarkedResult}>
                  <div className='flex items-center mb-1'>
                    <SparklesIcon className='size-5 block mr-2 shrink-0' />
                    <h3 className='text-lg font-semibold'>AI feedback</h3>
                  </div>
                </If>

                <SecureMarkdown content={feedback} />
              </div>
            </If>
          </If>
        </When>

        <Otherwise>
          <TextEditor.Container>
            <If condition={fields.length > 0}>
              <TextEditor.AttachmentList>
                <For each='attachment' of={fields}>
                  <TextEditor.Attachment
                    key={attachment.id}
                    id={attachment.id}
                    filename={attachment.filename}
                    url={attachment.url}
                    remove={() => remove(attachment.id)}
                  />
                </For>
              </TextEditor.AttachmentList>
            </If>

            <Controller
              name='answer'
              rules={{
                validate: value => (value || fields.length > 0) ? null : 'Answer or attachment is required'
              }}
              control={control}
              render={({ field }) => (
                <TextEditor
                  disabled={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED'}
                  className='min-h-[200px]'
                  {...field}
                />
              )}
            />

            <TextEditor.Actions>
              <Choose>
                <When condition={isUploading}>
                  <CircleSpinner className='p-1 mr-2' />
                </When>

                <Otherwise>
                  <TextEditor.FileInput
                    onChange={handleFilesAttached}
                    disabled={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED'}
                  />
                </Otherwise>
              </Choose>

              <TextEditor.ToolbarToggle />
            </TextEditor.Actions>
          </TextEditor.Container>

          <If condition={errors.answer}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.answer.message}</p>
          </If>

          <If condition={errors.attachments}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.attachments.message}</p>
          </If>
        </Otherwise>
      </Choose>

      <div className='flex flex-row items-center justify-between mt-5'>
        <LinkButton
          type='button'
          disabled={isFirstBlock}
          onClick={moveToPreviousBlock}
        >
          back
        </LinkButton>

        <Choose>
          <When condition={isLastBlock}>
            <Button
              type='submit'
              id='submit-button'
              className='self-end'
              disabled={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed}
              label={(submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED') ? 'Submitted' : 'Submit'}
            />

            <If condition={isSubmissionClosed}>
              <Tooltip anchorSelect='#submit-button' delayShow={200}>
                Submissions are now closed for this lesson
              </Tooltip>
            </If>
          </When>

          <Otherwise>
            <Button
              type='submit'
              className='self-end'
              label='Next'
            />
          </Otherwise>
        </Choose>
      </div>
    </form>
  )
}

export default FreeResponseBlock
