import { useRef, useEffect } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import { useCurrentUser } from '@contexts/currentUser'
import { formatShortTimestamp } from '@helpers/format'
import Spinner from '@components/Spinner'
import Modal from '@components/Modal'
import ShowMore from '@components/ShowMore'

const CHAT_MESSAGES_QUERY = gql`
  query chatMessageHistory($id: ID!, $page: Int!) {
    node(id: $id) {
      ... on EducatorProjectSubmission {
        chatMessages(page: $page, perPage: 10, sortDirection: "ASC") {
          pagesCount
          nodesCount
          nodes {
            id
            status
            from
            text
            createdAt
          }
        }
      }
    }
  }
`

const MessageHistoryModal = ({ id, closeModal }) => {
  const messageThreadRef = useRef(null)
  const { currentMembership: { organization: { id: organizationId } } } = useCurrentUser()
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status
  } = useInfiniteQuery({
    queryKey: ['chatMessages', id],
    queryFn: async ({ pageParam = 1 }) => request(CHAT_MESSAGES_QUERY, { id, page: pageParam }, { 'X-Organization-Id': organizationId }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.node.chatMessages.pagesCount > pages.length) {
        return pages.length + 1
      }

      return false
    }
  })

  useEffect(() => {
    if (status === 'success') {
      messageThreadRef.current.scrollTo(0, messageThreadRef.current.scrollHeight)
    }
  }, [status])

  return (
    <Modal size='md' isOpen onClose={closeModal}>
      <h2 className='text-2xl font-bold mb-3'>Chat history</h2>

      <div className='relative flex flex-col h-full'>
        <div ref={messageThreadRef} className=''>
          <Choose>
            <When condition={status === 'loading'}>
              <Spinner className='self-center' />
            </When>

            <When condition={data.pages?.[0]?.node?.chatMessages?.nodesCount === 0}>
              <p>No chat messages sent just yet 😀</p>
            </When>

            <Otherwise>
              <For each='page' of={data.pages} index='index'>
                <div key={`page-${index}`} className='flex flex-col'>
                  <For each='message' of={page.node.chatMessages.nodes}>
                    <div key={message.id} className='flex flex-row mb-2'>
                      <p className='text-sm whitespace-nowrap mr-3 font-semibold'>
                        {formatShortTimestamp(message.createdAt)}
                      </p>

                      <div className='text-sm'>
                        <p className='whitespace-pre-wrap'>
                          <Choose>
                            <When condition={message.from === 'ASSISTANT'}>
                              <mark className='whitespace-pre-wrap bg-green-100'>
                                <ShowMore content={message.text} />
                              </mark>
                            </When>

                            <When condition={message.status === 'MODERATION'}>
                              <ShowMore content={message.text} />
                              <mark className='whitespace-pre-wrap bg-red-100 block'>Unsafe content detected</mark>
                            </When>

                            <Otherwise>
                              <ShowMore content={message.text} />
                            </Otherwise>
                          </Choose>
                        </p>
                      </div>
                    </div>
                  </For>
                </div>
              </For>

              <Choose>
                <When condition={isFetchingNextPage}>
                  <Spinner className='self-center mb-3' />
                </When>

                <When condition={hasNextPage}>
                  <button
                    type='button'
                    className='self-center text-blue-500'
                    onClick={fetchNextPage}
                  >
                    load more
                  </button>
                </When>
              </Choose>
            </Otherwise>
          </Choose>
        </div>
      </div>
    </Modal>
  )
}

export default MessageHistoryModal
