import { useState, useRef } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { FormProvider, useForm } from 'react-hook-form'
import { LightBulbIcon } from '@heroicons/react/24/outline'

import { useCurrentUser } from '@contexts/currentUser'
import BreadCrumbs from '@components/Breadcrumbs'
import Tab from '@components/Tab'
import { useBreakpoint } from '@contexts/breakpoints'

import { useEducatorProjectSubmission } from './hooks'
import ChatPlayground from './ChatPlayground'
import Skeleton from './Skeleton'
import SubmitModal from './SubmitModal'
import ProjectBlocks from './ProjectBlocks'

const EducatorProjectSubmission = () => {
  const contentPanelRef = useRef(null)
  const isMobile = useBreakpoint('sm')
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false)
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0)
  const { id: educatorProjectId } = useParams()
  const { project, submission, isLoading } = useEducatorProjectSubmission({ educatorProjectId })
  const { currentMembership: { organization: { features } } } = useCurrentUser()
  const isCourseInsightsEnabled = features.includes('course_insights')

  const methods = useForm({ mode: 'onTouched', defaultValues: { message: null, attachments: [] } })

  const currentBlock = project?.blocks[currentBlockIndex]
  const currentBlockSubmission = submission?.blockSubmissions?.find(blockSubmission => blockSubmission.blockId === currentBlock?.blockId)
  const projectName = project?.name || 'Untitled lesson'
  const seriesName = project?.series?.name || 'Untitled pathway'
  const assignment = project?.series?.assignment

  if (isLoading || !project || !submission) return <Skeleton />

  const moveToNextBlock = () => {
    setCurrentBlockIndex(currentBlockIndex + 1)

    contentPanelRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const moveToPreviousBlock = () => {
    setCurrentBlockIndex(currentBlockIndex - 1)

    contentPanelRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <BreadCrumbs className='overflow-x-auto'>
        <BreadCrumbs.Link label='Pathways' to='/students/courses' />
        <BreadCrumbs.Link label={seriesName} to={`/students/courses/${project.series.id}`} />
        <BreadCrumbs.Text label={projectName} />

        <div className='flex items-center gap-3 ml-auto'>
          <If condition={(submission.status === 'COMPLETED' || submission.status === 'RETURNED') && isCourseInsightsEnabled}>
            <Link
              className='flex shrink-0 text-sm ml-auto text-blue-500 hover:bg-blue-100 rounded-md p-1'
              to={`/students/courses/${project.series.id}/lessons/${project.id}/insights`}
            >
              <LightBulbIcon className='size-5 inline-block mr-1' />
              Insights
            </Link>
          </If>

          <If condition={submission.status === 'RETURNED' && submission?.score && project?.totalPossibleScore}>
            <p className='flex text-sm ml-auto text-gray-500 shrink-0 bg-gray-100 rounded-md p-1 border border-gray-300'>
              {submission?.score} / {project?.totalPossibleScore}
            </p>
          </If>
        </div>
      </BreadCrumbs>

      <Choose>
        <When condition={isMobile}>
          <Tab.Group className='h-full'>
            <Tab.List className='sticky top-0'>
              <Tab>
                Lesson
              </Tab>

              <Tab>
                Chat
              </Tab>
            </Tab.List>

            <Tab.Panels className='h-[calc(100%-51px)]'>
              <Tab.Panel className='h-full'>
                <div ref={contentPanelRef} className='py-3 px-5 flex flex-col'>
                  <ProjectBlocks
                    blocks={project.blocks}
                    blockSubmissions={submission.blockSubmissions}
                    submissionId={submission.id}
                    submissionStatus={submission.status}
                    currentBlock={currentBlock}
                    currentBlockSubmission={currentBlockSubmission}
                    currentBlockIndex={currentBlockIndex}
                    moveToNextBlock={moveToNextBlock}
                    moveToPreviousBlock={moveToPreviousBlock}
                    isSubmissionClosed={project?.series?.assignment?.closed}
                    setIsSubmitModalOpen={setIsSubmitModalOpen}
                  />
                </div>
              </Tab.Panel>

              <Tab.Panel className='h-full w-full bg-white'>
                <FormProvider {...methods}>
                  <ChatPlayground
                    submissionId={submission.id}
                    isSubmissionClosed={assignment?.closed}
                    proFeaturesEnabled={assignment?.proFeaturesEnabled}
                    submissionStatus={submission.status}
                  />
                </FormProvider>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </When>

        <Otherwise>
          <PanelGroup className='flex grow bg-white' style={{ overflow: 'auto' }} direction='horizontal' autoSaveId='studentProjectPlayground'>
            <Panel
              style={{ overflow: 'auto' }}
              className='flex flex-col pl-5 pr-3 bg-gray-50'
              defaultSize={50}
              minSize={30}
            >
              <div className='py-3' ref={contentPanelRef}>
                <ProjectBlocks
                  blocks={project.blocks}
                  blockSubmissions={submission.blockSubmissions}
                  submissionId={submission.id}
                  submissionStatus={submission.status}
                  currentBlock={currentBlock}
                  currentBlockSubmission={currentBlockSubmission}
                  currentBlockIndex={currentBlockIndex}
                  moveToNextBlock={moveToNextBlock}
                  moveToPreviousBlock={moveToPreviousBlock}
                  isSubmissionClosed={assignment?.closed}
                  setIsSubmitModalOpen={setIsSubmitModalOpen}
                />
              </div>
            </Panel>

            <PanelResizeHandle className='flex items-center justify-center h-full'>
              <div className='bg-gray-50 px-1 h-full' />
              <div className='w-[1px] h-full bg-gray-200' />
              <div className='bg-white px-1 h-full' />
            </PanelResizeHandle>

            <Panel
              className='relative overflow-auto'
              defaultSize={50}
              minSize={30}
            >
              <FormProvider {...methods}>
                <ChatPlayground
                  submissionId={submission.id}
                  isSubmissionClosed={assignment?.closed}
                  proFeaturesEnabled={assignment?.proFeaturesEnabled}
                  submissionStatus={submission.status}
                />
              </FormProvider>
            </Panel>
          </PanelGroup>
        </Otherwise>
      </Choose>

      <SubmitModal
        seriesId={project?.series?.id}
        educatorProjectId={educatorProjectId}
        closeModal={() => setIsSubmitModalOpen(false)}
        isOpen={isSubmitModalOpen}
      />
    </>
  )
}

export default EducatorProjectSubmission
