import React from 'react'
import { gql } from 'graphql-request'
import { useQuery } from '@tanstack/react-query'
import { useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { FlagIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import BreadCrumbs from '@components/Breadcrumbs'
import { useCurrentUser } from '@contexts/currentUser'
import Image from '@components/Image'
import ButtonLink from '@components/ButtonLink'
import SecureMarkdown from '@components/SecureMarkdown'

import Skeleton from './Skeleton'
import NotFound from './NotFound'

const TUTOR_QUERY = gql`
  query tutor($id: ID!) {
    tutor(id: $id) {
      id
      name
      summary
      image {
        url
      }
      publicEducatorProfile {
        fullName
        biography
        profileImage {
          url
        }
      }
    }
  }
`

const PublicTutorDetails = () => {
  const navigate = useNavigate()
  const { user, currentMembership } = useCurrentUser()
  const { id } = useParams()

  const { isLoading, data: { tutor } = {} } = useQuery({
    queryKey: ['publicTutorDetails', id],
    queryFn: async () => request(TUTOR_QUERY, { id })
  })

  const { name, summary, image, publicEducatorProfile } = tutor || {}

  if (user?.signedIn && currentMembership?.role === 'student') navigate('/students')
  if (user?.signedIn && (currentMembership?.role === 'educator' || currentMembership?.role === 'admin')) navigate(`/educators/tutors/${id}`)
  if (isLoading) return <Skeleton />

  return (
    <Choose>
      <When condition={!tutor}>
        <NotFound />
      </When>

      <Otherwise>
        <Helmet>
          <title>Mindjoy - {name}</title>
          <meta name='description' content={`Sign up to chat with ${name}`} />

          <meta property='og:title' content={`Mindjoy - ${name}`} />
          <meta property='og:description' content={`Sign up to chat with ${name}`} />
          <meta property='og:type' content='website' />
          <meta property='og:url' content={window.location.href} />
          <If condition={image}>
            <meta property='og:image' content={image?.url} />
          </If>
        </Helmet>

        <div>
          <BreadCrumbs>
            <BreadCrumbs.Link label='Tutors' to='/sign_in' />
            <BreadCrumbs.Text label={name} />

            <a
              className='flex items-center ml-auto'
              href={`https://form.typeform.com/to/MMD2d5nk#tutor_id=${id}`}
              target='_blank'
              rel='noreferrer'
            >
              Report
              <FlagIcon className='ml-2 h-5 w-5' />
            </a>

          </BreadCrumbs>

          <div className='w-full h-[calc(100%-45px)] flex flex-col justify-start items-center text-center sm:mt-10'>
            <If condition={image}>
              <Image
                className='object-cover w-[400px] sm:rounded-lg rounded-none mb-5'
                src={image?.url}
                placeholder={<div className='sm:rounded-lg rounded-none w-[400px] h-[300px] bg-gray-200 animate-pulse mb-5' />}
              />
            </If>

            <h2 className='text-3xl font-bold'>{name}</h2>
            <p className='text-gray-700 text-lg'>By <span className='font-sembold'>{publicEducatorProfile?.fullName}</span></p>
            <p className='mt-5 mb-3 text-lg'>{summary}</p>

            <ButtonLink
              to='/sign_up'
              className='mt-5 motion-safe:animate-[bounce_1s_ease-in-out_3.5]'
              theme='secondary'
              label='Sign up to chat'
              onClick={() => localStorage.setItem('signUpTutorId', id)}
            />

            <If condition={publicEducatorProfile?.biography}>
              <div className='w-2/3 mt-10'>
                <div className='w-full border-t mb-10 border-gray-200' />

                <div className='flex md:flex-row flex-col justify-center'>
                  <Image
                    className='object-cover w-16 h-16 rounded-full mb-3 md:mb-0'
                    src={publicEducatorProfile?.profileImage?.url}
                    placeholder={<div className='w-16 h-16 shrink-0 bg-gray-200 animate-pulse rounded-full mb-3 md:mb-0' />}
                  />

                  <div className='flex flex-col md:ml-5 md:items-start items-center text-center md:text-left mb-5'>
                    <p className='font-semibold text-lg'>{publicEducatorProfile?.fullName}</p>
                    <SecureMarkdown content={publicEducatorProfile?.biography} />
                  </div>
                </div>
              </div>
            </If>
          </div>
        </div>
      </Otherwise>
    </Choose>
  )
}

export default PublicTutorDetails
