import { useMutation } from '@hooks/graphql'
import { gql } from 'graphql-request'
import { useNavigate } from 'react-router-dom'

import Button from '@components/Button'
import Modal from '@components/Modal'

const DELETE_TUTOR_MUTATION = gql`
  mutation deleteTutor($input: DeleteTutorInput!) {
    deleteTutor(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const DeleteTutorModal = ({
  closeModal,
  isOpen,
  id
}) => {
  const navigate = useNavigate()
  const { mutate: deleteTutor, isLoading: isDeleting } = useMutation({
    gqlMutation: DELETE_TUTOR_MUTATION,
    variables: { input: { id } },
    onSuccess: () => {
      closeModal()
      navigate('/educators/tutors')
    }
  })

  return (
    <Modal
      size='sm'
      isOpen={isOpen}
      onClose={closeModal}
    >
      <h3 className='font-heading text-2xl font-bold mb-3'>Delete tutor</h3>
      <p className='mb-3'>Are you sure you'd like to delete this tutor? This can't be undone.</p>
      <p className='mb-3'>Deleting this tutor will remove all its associated messages, sessions and student data.</p>

      <div className='flex justify-end'>
        <Button
          onClick={() => deleteTutor()}
          disabled={isDeleting}
          theme='error'
          label={isDeleting ? 'Deleting...' : 'Delete'}
          className='w-fit mt-3 self-end'
        />
      </div>
    </Modal>
  )
}

export default DeleteTutorModal
