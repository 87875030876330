import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'
import { Bars3BottomLeftIcon, SparklesIcon } from '@heroicons/react/24/outline'

import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import TextInput from '@components/TextInput'
import TextEditor from '@components/TextEditor'
import SecureMarkdown from '@components/SecureMarkdown'
import Label from '@components/Label'
import Image from '@components/Image'

const UPDATE_FREE_RESPONSE_SUBMISSION = gql`
  mutation UpdateFreeResponseSubmission($input: UpdateFreeResponseSubmissionInput!) {
    updateFreeResponseSubmission(input: $input) {
      blockSubmission {
        id
        status
        blockId
        content {
          ... on FreeResponseSubmission {
            __typename
            answer
            marks
            feedback
            reasoning
            attachments {
              id
              filename
              url
            }
          }
        }
      }
    }
  }
`
const FreeResponseQuestionForm = ({ id, question, markAllocation, answer, marks, feedback, reasoning, automarkedResult, attachments, refetch, closeModal }) => {
  const { formState: { errors }, handleSubmit, register, control } = useForm({
    mode: 'onTouched',
    defaultValues: {
      marks,
      feedback
    }
  })

  const { mutate: updateFreeResponseSubmission, isLoading: isUpdating } = useMutation({
    gqlMutation: UPDATE_FREE_RESPONSE_SUBMISSION,
    onSuccess: () => {
      refetch()
      closeModal()
    }
  })

  const submit = data => updateFreeResponseSubmission({ input: { blockSubmissionId: id, ...data } })

  return (
    <div>
      <div className='flex items-center mb-5'>
        <Bars3BottomLeftIcon className='size-8 mr-3 bg-orange-200 rounded-md p-1 shrink-0' />
        <h3 className='text-2xl font-bold leading-none'>Free Response</h3>
      </div>

      <SecureMarkdown content={question} />

      <div className='bg-gray-100 p-3 rounded-lg mt-5'>
        <SecureMarkdown content={answer} />

        <If condition={attachments.length > 0}>
          <For each='attachment' of={attachments}>
            <Image
              className='object-contain h-[200px] rounded-md mt-3'
              src={attachment.url}
              placeholder={<div className='rounded-md h-[200px] w-1/3 bg-gray-200 animate-pulse mt-3' />}
            />
          </For>
        </If>
      </div>

      <form onSubmit={handleSubmit(submit)} className='flex flex-col mt-3 gap-3'>
        <div>
          <div className='flex items-end gap-3'>
            <TextInput
              id='marks'
              label='Marks'
              type='number'
              className='block w-fit'
              inputClassName='w-[150px]'
              {...register('marks', {
                valueAsNumber: true,
                required: 'Marks are required',
                min: { value: 0, message: "Mark can't be negative" },
                max: { value: markAllocation, message: 'Mark cannot be greater than the mark allocation' }
              })}
            />

            <span className='text-lg font-semibold mb-1'> / {markAllocation}</span>
          </div>

          <If condition={errors.marks}>
            <p className='mt-1 text-sm font-semibold text-red-500' role='alert'>{errors.marks.message}</p>
          </If>
        </div>

        <If condition={automarkedResult && reasoning}>
          <div className='flex bg-purple-100 p-3 rounded-lg leading-tight'>
            <SparklesIcon className='size-5 block mr-2 shrink-0' />
            <SecureMarkdown content={reasoning} />
          </div>
        </If>

        <div>
          <Label htmlFor='feedback' title='Feedback' />
          <TextEditor.Container>
            <Controller
              name='feedback'
              control={control}
              render={({ field }) => (
                <TextEditor
                  id='feedback'
                  {...field}
                />
              )}
            />

            <TextEditor.Actions>
              <TextEditor.ToolbarToggle />
            </TextEditor.Actions>
          </TextEditor.Container>

          <If condition={errors.feedback}>
            <p className='mt-1 text-sm font-semibold text-red-500' role='alert'>{errors.feedback.message}</p>
          </If>
        </div>

        <Button
          className='mt-3 w-fit ml-auto'
          type='submit'
          label={isUpdating ? 'Saving...' : 'Save'}
          disabled={isUpdating}
        />
      </form>
    </div>
  )
}

export default FreeResponseQuestionForm
