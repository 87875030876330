import { gql } from 'graphql-request'

import { useQuery } from '@hooks/graphql'
import Modal from '@components/Modal'
import Spinner from '@components/Spinner'

import FreeResponseQuestionForm from './FreeResponseQuestionForm'
import MultipleChoiceQuestionForm from './MultipleChoiceQuestionForm'

const BLOCK_SUBMISSION_QUERY = gql`
  query blockSubmission($id: ID!) {
    blockSubmission: node(id: $id) {
      ... on BlockSubmission {
        id
        status
        blockId
        content {
          ... on FreeResponseSubmission {
            __typename
            answer
            marks
            feedback
            reasoning
            automarkedResult
            attachments {
              id
              filename
              url
            }
          }
          ... on QuestionSubmission {
            __typename
            answer {
              id
              label
              correct
            }
            marks
          }
        }
        block {
          __typename
          blockId
          ... on Reflection {
            __typename
            id
            question
          }
          ... on Question {
            __typename
            id
            label
            markAllocation
            options {
              id
              label
              correct
            }
          }
          ... on FreeResponse {
            __typename
            id
            question
            markAllocation
            markScheme
            workingsRequired
          }
        }
      }
    }
  }
`

const QuestionSubmissionModal = ({ closeModal, submissionId, refetch }) => {
  const { data: { blockSubmission: { block, ...blockSubmission } = {} } = {}, isLoading } = useQuery({
    queryKey: ['blockSubmission', submissionId],
    gqlQuery: BLOCK_SUBMISSION_QUERY,
    variables: { id: submissionId },
    enabled: !!submissionId,
    cacheTime: 0,
    staleTime: 0
  })

  return (
    <Modal size='md' isOpen onClose={closeModal}>
      <Choose>
        <When condition={isLoading}>
          <Spinner className='flex items-center justify-center w-full my-32' />
        </When>

        <When condition={blockSubmission.content.__typename === 'FreeResponseSubmission'}>
          <FreeResponseQuestionForm
            id={blockSubmission.id}
            question={block.question}
            markAllocation={block.markAllocation}
            markScheme={block.markScheme}
            answer={blockSubmission.content.answer}
            marks={blockSubmission.content.marks}
            feedback={blockSubmission.content.feedback}
            automarkedResult={blockSubmission.content.automarkedResult}
            reasoning={blockSubmission.content.reasoning}
            attachments={blockSubmission.content.attachments}
            refetch={refetch}
            closeModal={closeModal}
          />
        </When>

        <When condition={blockSubmission.content.__typename === 'QuestionSubmission'}>
          <MultipleChoiceQuestionForm
            id={blockSubmission.id}
            question={block.label}
            options={block.options}
            markAllocation={block.markAllocation}
            answer={blockSubmission.content.answer}
            marks={blockSubmission.content.marks}
            refetch={refetch}
            closeModal={closeModal}
          />
        </When>
      </Choose>
    </Modal>
  )
}

export default QuestionSubmissionModal
