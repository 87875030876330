import { gql } from 'graphql-request'

import { useQuery, useMutation } from '@hooks/graphql'

const EDUCATOR_PROJECT_QUERY = gql`
  query educatorProject($id: ID!) {
    node(id: $id) {
      ... on EducatorProject {
        id
        name
        status
        playground
        prompt
        socraticModeEnabled
        stemModeEnabled
        createdAt
        updatedAt
        tutor {
          id
          name
          description
          summary
          image {
            url
          }
        }
        series {
          id
          name
        }
        blocks {
          blockId
          position
          ... on Text {
            __typename
            id
            content
          }
          ... on Reflection {
            __typename
            id
            question
          }
          ... on Question {
            __typename
            id
            label
            markAllocation
            options {
              id
              label
              correct
            }
          }
          ... on FreeResponse {
            __typename
            id
            question
            markAllocation
            markScheme
            workingsRequired
          }
          ... on Video {
            __typename
            id
            url
          }
          ... on Image {
            __typename
            id
            caption
            file {
              filename
              url
            }
          }
        }
        educatorProfile {
          id
          fullName
        }
        organization {
          id
          name
        }
      }
    }
  }
`

const SUBMISSIONS_QUERY = gql`
  query submissions($id: ID!, $page: Int) {
    node(id: $id) {
      ... on EducatorProject {
        submissions(page: $page, perPage: 10) {
          nodes {
            id
            status
            profile {
              id
              fullName
            }
          }
          pagesCount
          nodesCount
        }
      }
    }
  }
`

const UPDATE_EDUCATOR_PROJECT_MUTATION = gql`
  mutation updateEducatorProject($updateEducatorProjectInput: UpdateEducatorProjectInput!) {
    updateEducatorProject(input: $updateEducatorProjectInput) {
      educatorProject {
        id
        name
        status
        playground
        socraticModeEnabled
        stemModeEnabled
        tutor {
          id
          name
        }
        createdAt
        updatedAt
        educatorProfile {
          id
          fullName
        }
        organization {
          id
          name
        }
      }
    }
  }
`

const CLASSROOMS_QUERY = gql`
  query classrooms {
    classrooms {
      id
      name
    }
  }
`

const useEducatorProject = ({ id, refetchInterval }) => {
  const { status, refetch, data: { node: project } = {} } = useQuery({
    queryKey: ['educatorProject', id],
    gqlQuery: EDUCATOR_PROJECT_QUERY,
    variables: { id },
    refetchInterval
  })

  return { project, status, refetch }
}

const useUpdateEducatorProject = ({ id, onSuccess }) => {
  const { mutate: updateProjectMutation, status } = useMutation({
    gqlMutation: UPDATE_EDUCATOR_PROJECT_MUTATION,
    onSuccess: () => {
      if (onSuccess) onSuccess()
    }
  })

  const updateProject = variables => updateProjectMutation({ updateEducatorProjectInput: { ...variables, id } })

  return { updateProject, status }
}

const useClassrooms = () => {
  const { isLoading, data: { classrooms = [] } = {} } = useQuery({
    queryKey: ['classrooms'],
    gqlQuery: CLASSROOMS_QUERY
  })

  return { classrooms, isLoading }
}

const useSubmissions = ({ id, page }) => {
  const { status, refetch, data: { node: { submissions = {} } = {} } = {} } = useQuery({
    queryKey: ['educatorProjectSubmissions', id, page],
    gqlQuery: SUBMISSIONS_QUERY,
    variables: { id, page }
  })

  return { submissions, status, refetch }
}

export {
  useEducatorProject,
  useUpdateEducatorProject,
  useClassrooms,
  useSubmissions
}
