import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { CheckIcon } from '@heroicons/react/24/solid'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import TextInput from '@components/TextInput'

const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation requestPasswordReset($requestPasswordResetInput: RequestPasswordResetInput!) {
    requestPasswordReset(input: $requestPasswordResetInput) {
      success
      errors
    }
  }
`

const RequestPasswordReset = () => {
  const { register, handleSubmit, reset } = useForm({ mode: 'onTouched' })

  const { mutate: requestPasswordReset, isLoading: sending, isSuccess } = useMutation({
    mutationFn: async variables => await request(REQUEST_PASSWORD_RESET_MUTATION, variables),
    onSuccess: () => reset()
  })

  const submit = data => {
    requestPasswordReset({ requestPasswordResetInput: data })
  }

  return (
    <>
      <If condition={isSuccess}>
        <div className='flex flex-row items-center self-center font-semibold bg-green-100 border-green-800 border-2 rounded-md p-5 mt-5'>
          <CheckIcon className='h-6 w-6 text-black mr-3' />
          <p>
            You'll receive an email with password reset instructions shortly
          </p>
        </div>
      </If>

      <Card className='md:max-w-[500px] self-center p-5 md:mt-10 mt-5'>
        <h1 className='text-2xl font-bold mb-3'>Reset your password</h1>
        <p>Enter your email address and we'll send you instructions to reset your password.</p>

        <form className='flex flex-col space-y-4 mt-5' onSubmit={handleSubmit(submit)}>
          <TextInput
            id='email'
            label='Email'
            type='email'
            required
            {...register('email', { required: 'Email is required', pattern: { value: /(.+)@(.+){2,}\.(.+){2,}/i, message: 'Email must be valid' } })}
          />

          <Button type='submit' disabled={sending} label={sending ? 'Sending...' : 'Send reset instructions'} />
        </form>
      </Card>
    </>
  )
}

export default RequestPasswordReset
