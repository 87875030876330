import { GraphQLClient } from 'graphql-request'

import { csrfToken } from './csrf'

const endpoint = import.meta.env.VITE_API_URL + '/graphql'
const graphQLClient = new GraphQLClient(endpoint, {
  credentials: 'include',
  crossDomain: true
})

export const request = async (query, variables, headers = {}) => {
  const requestHeaders = {
    'X-CSRF-token': csrfToken(),
    ...headers
  }

  return graphQLClient.request(query, variables, requestHeaders)
}
