import { useInfiniteQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { useCurrentUser } from '@contexts/currentUser'
import { request } from '@helpers/graphql'
import { formatShortTimestamp } from '@helpers/format'
import Image from '@components/Image'
import Spinner from '@components/Spinner'
import Button from '@components/Button'
import ShowMore from '@components/ShowMore'
import Modal from '@components/Modal'

const MESSAGES_QUERY = gql`
  query chat($id: ID!, $page: Int!) {
    node(id: $id) {
      ... on Chat {
        messages(page: $page, perPage: 10) {
          pagesCount
          nodesCount
          nodes {
            id
            status
            from
            text
            createdAt
            attachments {
              url
              filename
            }
          }
        }
      }
    }
  }
`

const ChatHistoryModal = ({ chatId, isOpen, closeModal }) => {
  const { currentMembership: { organization: { id: organizationId } } } = useCurrentUser()
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status
  } = useInfiniteQuery({
    queryKey: ['messages', chatId],
    queryFn: async ({ pageParam = 1 }) => request(MESSAGES_QUERY, { id: chatId, page: pageParam }, { 'X-Organization-Id': organizationId }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.node.messages.pagesCount > pages.length) {
        return pages.length + 1
      }

      return false
    }
  })

  return (
    <Modal
      size='lg'
      title='Chat history'
      isOpen={isOpen}
      onClose={closeModal}
    >
      <h2 className='text-2xl font-bold mb-3'>Chat history</h2>

      <div className='relative flex flex-col h-full'>

        <Choose>
          <When condition={status === 'loading'}>
            <Spinner className='self-center' />
          </When>

          <When condition={data.pages?.[0]?.node?.messages?.nodesCount === 0}>
            <p>No messages sent just yet 😀</p>
          </When>

          <Otherwise>
            <For each='page' of={data.pages}>
              <div className='flex flex-col'>
                <For each='message' of={page.node.messages.nodes}>
                  <div className='flex flex-row mb-2'>
                    <p className='text-sm whitespace-nowrap mr-3 font-semibold'>
                      {formatShortTimestamp(message.createdAt)}
                    </p>

                    <div className='text-base'>
                      <p className='whitespace-pre-wrap'>
                        <Choose>
                          <When condition={message.from === 'ASSISTANT'}>
                            <mark className='whitespace-pre-wrap bg-green-100'>
                              <ShowMore content={message.text} />
                            </mark>
                          </When>

                          <When condition={message.status === 'MODERATION'}>
                            <ShowMore content={message.text} />
                            <mark className='whitespace-pre-wrap bg-red-100 block'>Unsafe content detected</mark>
                          </When>

                          <Otherwise>
                            <ShowMore content={message.text} />

                            <For each='attachment' of={message.attachments}>
                              <Image
                                className='ml-auto object-cover w-auto max-h-[200px] rounded-lg'
                                placeholder={<div className='w-[300px] h-[200px] bg-gray-200 animate-pulse rounded-lg' />}
                                key={attachment.filename}
                                src={attachment.url}
                                alt={attachment.filename}
                              />
                            </For>
                          </Otherwise>
                        </Choose>
                      </p>
                    </div>
                  </div>
                </For>
              </div>
            </For>

            <Choose>
              <When condition={isFetchingNextPage}>
                <Spinner className='self-center mb-3' />
              </When>

              <When condition={hasNextPage}>
                <Button
                  theme='light'
                  variant='outlined'
                  className='self-center'
                  label='Load more'
                  onClick={fetchNextPage}
                />
              </When>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    </Modal>
  )
}

export default ChatHistoryModal
