import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'

import Button from '@components/Button'
import TextEditor from '@components/TextEditor'
import Label from '@components/Label'
import { useMutation } from '@hooks/graphql'

const UPDATE_TEXT_BLOCK_MUTATION = gql`
  mutation updateTextBlock($input: UpdateTextBlockInput!) {
    updateTextBlock(input: $input) {
      text {
        content
      }
      errors {
        message
      }
    }
  }
`

const TextForm = ({ id, content, refetch, uploadImage }) => {
  const { control, handleSubmit } = useForm({ defaultValues: { content } })
  const { mutate: updateTextBlock, isLoading } = useMutation({
    gqlMutation: UPDATE_TEXT_BLOCK_MUTATION,
    onSuccess: () => refetch()
  })

  const onSubmit = ({ content }) => {
    updateTextBlock({ input: { id, content } })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-full h-full flex flex-col px-5 py-5'>
      <Label labelFor='content' title='Content' />

      <TextEditor.Container className='h-full max-h-full'>
        <Controller
          name='content'
          control={control}
          render={({ field }) => (
            <TextEditor
              id='content'
              uploadImage={uploadImage}
              {...field}
            />
          )}
        />

        <TextEditor.Actions>
          <TextEditor.ToolbarToggle />
        </TextEditor.Actions>
      </TextEditor.Container>

      <Button
        className='mt-5 ml-auto'
        type='submit'
        disabled={isLoading}
        label={isLoading ? 'Saving...' : 'Save'}
      />
    </form>
  )
}

export default TextForm
