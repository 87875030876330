import { useForm } from 'react-hook-form'
import { gql } from 'graphql-request'
import { CheckIcon } from '@heroicons/react/24/outline'

import { useCurrentUser } from '@contexts/currentUser'
import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import TextInput from '@components/TextInput'
import PasswordInput from '@components/PasswordInput'

const UPDATE_USER_MUTATION = gql`
  mutation updateUser($updateUserInput: UpdateUserInput!) {
    updateUser(input: $updateUserInput) {
      user {
        firstName
        lastName
      }
      errors {
        message
      }
    }
  }
`

const Profile = () => {
  const { user } = useCurrentUser()

  const { register, handleSubmit, formState: { errors }, setError } = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstName: user.first_name,
      lastName: user.last_name
    }
  })
  const { mutateAsync: updateUser, isLoading, isSuccess } = useMutation({
    gqlMutation: UPDATE_USER_MUTATION,
    onError: error => {
      if (error?.response?.errors?.[0]?.message === 'Current password is incorrect') {
        setError('currentPassword', { message: 'Current password is incorrect' })
      } else {
        setError('general', { message: 'Oops! Something went wrong.' })
      }
    }
  })

  return (
    <Card className='p-5'>
      <h2 className='text-2xl font-bold font-heading'>Profile</h2>

      <If condition={errors.general}>
        <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.general.message}</p>
      </If>

      <form className='flex flex-col space-y-4 mt-5' onSubmit={handleSubmit(data => updateUser({ updateUserInput: data }))}>
        <div>
          <PasswordInput
            id='current-password'
            label='Current password'
            required
            {...register('currentPassword', { required: 'Current password is required to make account changes' })}
          />
          <If condition={errors.currentPassword}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.currentPassword.message}</p>
          </If>
        </div>

        <div className='flex flex-row justify-between'>
          <TextInput
            className='w-full mr-5'
            id='first-name'
            label='First name'
            {...register('firstName')}
          />

          <div className='w-full'>
            <TextInput
              id='last-name'
              label='Last name'
              {...register('lastName')}
            />
          </div>
        </div>

        <div className='flex flex-row justify-end items-center'>
          <If condition={isSuccess}>
            <CheckIcon className='h-6 w-6 text-green-700 mr-3' />
          </If>

          <Button
            className='w-fit'
            type='submit'
            disabled={!!isLoading}
            label={isLoading ? 'Saving...' : 'Save'}
          />
        </div>
      </form>
    </Card>
  )
}

export default Profile
