import { useNavigate } from 'react-router-dom'
import { gql } from 'graphql-request'

import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { useFlashMessage } from '@components/FlashMessage'

const UPDATE_EDUCATOR_PROJECT_SUBMISSION_MUTATION = gql`
  mutation updateEducatorProjectSubmission($input: UpdateEducatorProjectSubmissionInput!) {
    updateEducatorProjectSubmission(input: $input) {
      educatorProjectSubmission {
        id
        status
      }
      errors {
        message
      }
    }
  }
`

const SubmitModal = ({ closeModal, isOpen, seriesId, educatorProjectId }) => {
  const navigate = useNavigate()
  const { setFlashMessage } = useFlashMessage()

  const { mutateAsync: submitProject, isLoading } = useMutation({
    gqlMutation: UPDATE_EDUCATOR_PROJECT_SUBMISSION_MUTATION,
    variables: { input: { educatorProjectId, status: 'COMPLETED' } }
  })

  const submit = async () => {
    await submitProject()

    closeModal()
    setFlashMessage('Your lesson has been submitted.', 'success')
    navigate(`/students/courses/${seriesId}`)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
    >
      <div className=' h-full'>
        <h3 className='font-heading text-2xl font-bold'>Submit lesson</h3>
        <p className='mt-3'>Would you like to submit this lesson? You won't be able to make changes again.</p>

        <div className='flex flex-row justify-between items-center mt-3'>
          <button
            className='text-gray-500 cursor-pointer'
            onClick={closeModal}
          >
            Cancel
          </button>

          <Button
            disabled={isLoading}
            label={isLoading ? 'Submitting...' : 'Submit'}
            onClick={submit}
          />
        </div>
      </div>
    </Modal>
  )
}

export default SubmitModal
