import { gql } from 'graphql-request'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { useQuery, useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import { useFlashMessage } from '@components/FlashMessage'
import Spinner from '@components/Spinner'
import Pill from '@components/Pill'

const QUERY_LEARNNG_MANAGEMENT_SYSTEM_CLASSROOMS = gql`
  query learningManagementSystemClassrooms {
    learningManagementSystem {
      classrooms {
        id
        name
        students {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`

const QUERY_LEARNNG_MANAGEMENT_SYSTEM_CLASSROOM = gql`
  query learningManagementSystemClassroom($externalId: ID!) {
    learningManagementSystem {
      classroom(externalId: $externalId) {
        id
        name
        students {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`

const IMPORT_STUDENTS_FROM_LEARNNG_MANAGEMENT_SYSTEM_MUTATION = gql`
  mutation importStudents($input: ImportStudentsInput!) {
    importStudents(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const ImportStudents = ({ classroomId, closeModal }) => {
  const { setFlashMessage } = useFlashMessage()
  const queryClient = useQueryClient()
  const { register, handleSubmit, reset, setError, watch, formState: { errors } } = useForm({ mode: 'onTouched' })
  const selectedExternalClassroomId = watch('externalClassroomId')

  const { isLoading, data: { learningManagementSystem: { classrooms = [] } = {} } = {} } = useQuery({
    queryKey: ['learningManagementSystemClassrooms'],
    gqlQuery: QUERY_LEARNNG_MANAGEMENT_SYSTEM_CLASSROOMS,
    cacheTime: 0,
    staleTime: 0,
    onError: error => {
      if (error?.response?.errors?.[0]?.message === 'Authorization error') {
        setError('general', { message: 'Oops! It looks like Mindjoy doesn\'t have permission to view your classrooms. Please reconnect your integration and try again.' })
      } else {
        setError('general', { message: 'Oops! Something went wrong.' })
      }
    }
  })

  const { isFetching: isClassroomFetching } = useQuery({
    queryKey: ['learningManagementSystemClassroom', selectedExternalClassroomId],
    gqlQuery: QUERY_LEARNNG_MANAGEMENT_SYSTEM_CLASSROOM,
    variables: { externalId: selectedExternalClassroomId },
    cacheTime: 0,
    staleTime: 0,
    enabled: !!selectedExternalClassroomId,
    onSuccess: data => {
      queryClient.setQueryData(['learningManagementSystemClassrooms'], oldData => {
        return {
          learningManagementSystem: {
            classrooms: oldData.learningManagementSystem.classrooms.map(c => {
              if (c.id === data.learningManagementSystem.classroom.id) {
                return data.learningManagementSystem.classroom
              }

              return c
            })
          }
        }
      })
    },
    onError: error => {
      if (error?.response?.errors?.[0]?.message === 'Authorization error') {
        setError('general', { message: 'Oops! It looks like Mindjoy doesn\'t have permission to view your classrooms. Please reconnect your integration and try again.' })
      } else {
        setError('general', { message: 'Oops! Something went wrong.' })
      }
    }
  })

  const { mutateAsync: importStudents, isLoading: isImporting } = useMutation({
    mutationKey: ['importStudents'],
    gqlMutation: IMPORT_STUDENTS_FROM_LEARNNG_MANAGEMENT_SYSTEM_MUTATION,
    onSuccess: () => {
      setFlashMessage('Students imported', 'success')
      closeModal()
      reset()
      queryClient.invalidateQueries('studentProfiles')
    },
    onError: error => {
      if (error?.response?.errors?.[0]?.message === 'Authorization error') {
        setError('general', { message: 'Oops! It looks like Mindjoy doesn\'t have permission to view your classrooms. Please reconnect your integration and try again.' })
      } else {
        setError('general', { message: 'Oops! Something went wrong.' })
      }
    }
  })

  const submit = data => {
    importStudents({ input: { classroomId, externalClassroomId: data.externalClassroomId } })
  }

  return (
    <Choose>
      <When condition={isLoading}>
        <Spinner className='my-5 w-full items-center justify-center' />
      </When>

      <Otherwise>
        <form
          noValidate
          onSubmit={handleSubmit(submit)}
          className='flex flex-col space-y-4 mt-5'
        >
          <fieldset>
            <legend className='font-semibold text-lg'>Choose a classroom to import students from</legend>
            <p className='mb-3'>This will create new student accounts and add them to your classroom.</p>

            <If condition={errors.general}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.general.message}</p>
            </If>

            <div className='space-y-5'>
              <For each='classroom' of={classrooms}>
                <div key={classroom.id} className='relative flex items-start'>
                  <div className='flex h-6 items-center'>
                    <input
                      id={classroom.id}
                      aria-describedby={`${classroom.id}-description`}
                      type='radio'
                      className='h-5 w-5 border-gray-300 text-blue-600 focus:ring-indigo-blue cursor-pointer'
                      required
                      value={classroom.id}
                      {...register('externalClassroomId', { required: 'Classroom is required' })}
                    />
                  </div>

                  <div className='ml-3 leading-6'>
                    <label htmlFor={classroom.id} className='text-base font-semibold cursor-pointer'>
                      {classroom.name}
                    </label>

                    <Choose>
                      <When condition={isClassroomFetching && selectedExternalClassroomId === classroom.id}>
                        <Spinner className='my-5 w-full items-center justify-center' />
                      </When>

                      <When condition={selectedExternalClassroomId === classroom.id}>
                        <Pill theme='light' className='text-sm ml-3' label={`${classroom.students.length} ${classroom.students.length === 1 ? 'student' : 'students'}`} />

                        <div className='mt-3'>
                          <For each='student' of={classroom.students}>
                            <p key={student.id}>
                              {student.firstName} {student.lastName} <span className='text-gray-500'>({student.email})</span>
                            </p>
                          </For>
                        </div>
                      </When>
                    </Choose>
                  </div>
                </div>
              </For>

              <If condition={errors.externalClassroomId}>
                <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.externalClassroomId.message}</p>
              </If>
            </div>
          </fieldset>

          <Button
            className='w-fit ml-auto'
            type='submit'
            label={isImporting ? 'Importing...' : 'Import students'}
          />
        </form>
      </Otherwise>
    </Choose>
  )
}

export default ImportStudents
