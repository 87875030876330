import { useState } from 'react'
import { gql } from 'graphql-request'
import { ClockIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'

import { useQuery } from '@hooks/graphql'
import Modal from '@components/Modal'
import RadioGroup from '@components/RadioGroup'
import CopyToClipboardInput from '@components/CopyToClipboardInput'
import Notification from '@components/Notification'

import { useSubscription } from '../../hooks/subscription'

import InviteStudentsForm from './InviteStudentsForm'
import ImportStudents from './ImportStudents'

const ImportStudentsLabel = ({ integration }) => (
  <div className='flex leading-tight items-start'>
    <Choose>
      <When condition={integration === 'GOOGLE_CLASSROOM'}>
        <img
          className='w-5 h-5 mr-3'
          src='/google-classroom-logo.png'
        />
        Import students from Google Classroom
      </When>

      <When condition={integration === 'MICROSOFT_TEAMS'}>
        <img
          className='w-5 h-5 mr-3'
          src='/microsoft-logo.svg'
        />
        Import students from Microsoft Teams for Education
      </When>
    </Choose>
  </div>
)

const LEARNING_MANAGEMENT_SYSTEM_QUERY = gql`
  query learningManagementSystem {
    learningManagementSystem {
      integration
    }
  }
`

const AddStudentsModal = ({ classroomId, shortCode, closeModal, isOpen, refetch }) => {
  const [mode, setMode] = useState(null)
  const { verificationStatus } = useSubscription()

  const { data: { learningManagementSystem: { integration = null } = {} } = {} } = useQuery({
    queryKey: ['learningManagementSystem'],
    gqlQuery: LEARNING_MANAGEMENT_SYSTEM_QUERY
  })

  return (
    <Modal
      size='lg'
      isOpen={isOpen}
      onClose={closeModal}
    >

      <h2 className='font-heading text-3xl font-bold mb-3'>Add students</h2>

      <Choose>
        <When condition={verificationStatus === 'PENDING'}>
          <Notification className='font-normal flex flex-col items-start flex-wrap mb-3' theme='primary'>
            <span className='flex'>
              <ClockIcon className='w-5 h-5 mr-2 text-blue-500' />
              <strong>
                Verification in progress
              </strong>
            </span>

            <p className='leading-tight'>
              We care about student safety and learning integrity.
              Your organization needs to be verified as an academic institution in order to add students.
              Verification is currently in progress,&nbsp;<a className='font-semibold text-blue-500 underline' href='mailto:support@mindjoy.com'>please contact support</a> if you have any questions.
            </p>
          </Notification>
        </When>

        <When condition={verificationStatus === 'REJECTED'}>
          <Notification className='font-normal flex flex-col items-start flex-wrap mb-3' theme='warning'>
            <span className='flex'>
              <ExclamationCircleIcon className='w-5 h-5 mr-2 text-orange-500' />
              <strong>
                Verification failed
              </strong>
            </span>

            <p className='leading-tight'>
              It looks like your organisation isn't an academic institution.
              You won't be able to add students without successful verification.
              Please&nbsp;<a className='font-semibold text-orange-500 underline' href='mailto:support@mindjoy.com'>please contact support</a> if you think this is a mistake.
            </p>
          </Notification>
        </When>
      </Choose>

      <RadioGroup
        value={mode}
        onChange={setMode}
        name='mode'
        label='How would you like to add students?'
        className='flex flex-row'
        disabled={verificationStatus !== 'VERIFIED'}
      >
        <If condition={!!integration}>
          <RadioGroup.Option
            label={<ImportStudentsLabel integration={integration} />}
            description='Import students from your learning management system.'
            value='import'
          />
        </If>
        <RadioGroup.Option
          label='Student join code'
          description='Students join your classroom by entering a code or following a link.'
          value='code'
        />
        <RadioGroup.Option
          label='Email invitations'
          description="Students will receive an email invitation to join your classroom if they haven't signed up yet."
          value='invitation'
        />
      </RadioGroup>

      <Choose>
        <When condition={mode === 'code'}>
          <div className='flex gap-3 mt-5'>
            <CopyToClipboardInput
              className='w-3/4'
              value={`${window.location.origin}/join_classroom/${shortCode}`}
              label='Student join link'
            />

            <CopyToClipboardInput
              value={shortCode}
              label='Student join code'
            />
          </div>
        </When>

        <When condition={mode === 'invitation'}>
          <InviteStudentsForm
            closeModal={closeModal}
            classroomId={classroomId}
          />
        </When>

        <When condition={mode === 'import'}>
          <ImportStudents
            closeModal={closeModal}
            classroomId={classroomId}
            refetch={refetch}
          />
        </When>
      </Choose>
    </Modal>
  )
}

export default AddStudentsModal
