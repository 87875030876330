import BreadCrumbs from '@components/Breadcrumbs'

const Skeleton = () => (
  <>
    <BreadCrumbs>
      <BreadCrumbs.Link label='Pathways' to='/students/courses' />

      <div className='w-20 h-4 bg-gray-200 ml-3' />

      <div className='w-20 h-4 bg-gray-200' />
    </BreadCrumbs>
  </>
)

export default Skeleton
