import { useState } from 'react'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'
import { BeakerIcon } from '@heroicons/react/24/outline'

import { useMutation } from '@hooks/graphql'
import TextInput from '@components/TextInput'
import Button from '@components/Button'
import Label from '@components/Label'
import TextEditor from '@components/TextEditor'

import PreviewFreeResponseQuestionModal from './PreviewFreeResponseQuestionModal'

const UPDATE_FREE_RESPONSE_BLOCK_MUTATION = gql`
  mutation updateFreeResponseBlock($input: UpdateFreeResponseBlockInput!) {
    updateFreeResponseBlock(input: $input) {
      freeResponse {
        id
        question
        markAllocation
        markScheme
        workingsRequired
      }
      errors {
        message
      }
    }
  }
`

const FreeResponseQuestionForm = ({ id, question, markAllocation, markScheme, workingsRequired, refetch, uploadImage }) => {
  const [isTestModalOpen, setIsTestModalOpen] = useState(false)

  const { control, register, handleSubmit, formState: { errors }, getValues } = useForm({
    mode: 'onTouched',
    defaultValues: {
      question,
      markAllocation,
      markScheme,
      workingsRequired
    }
  })

  const { mutate: updateFreeResponseBlock, isLoading } = useMutation({
    gqlMutation: UPDATE_FREE_RESPONSE_BLOCK_MUTATION,
    onSuccess: refetch
  })

  const onSubmit = data => {
    updateFreeResponseBlock({ input: { id, ...data } })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full flex flex-col px-5 py-5 gap-5'>
        <div>
          <Label htmlFor='question' title='Question' />

          <TextEditor.Container>
            <Controller
              name='question'
              control={control}
              render={({ field }) => (
                <TextEditor
                  uploadImage={uploadImage}
                  id='question'
                  {...field}
                />
              )}
            />

            <TextEditor.Actions>
              <TextEditor.ToolbarToggle />
            </TextEditor.Actions>
          </TextEditor.Container>

          <If condition={errors.question}>
            <p className='mt-1 text-sm font-semibold text-red-500' role='alert'>{errors.question.message}</p>
          </If>
        </div>

        <div>
          <TextInput
            id='mark-allocation'
            label='Mark allocation'
            type='number'
            className='block w-fit'
            inputClassName='w-[150px]'
            {...register('markAllocation', {
              valueAsNumber: true,
              required: 'Mark allocation is required',
              min: { value: 1, message: 'Mark allocation must be greater than 0' }
            })}
          />

          <small>The maximum mark allocation for this question</small>

          <If condition={errors.markAllocation}>
            <p className='mt-1 text-sm font-semibold text-red-500' role='alert'>{errors.markAllocation.message}</p>
          </If>
        </div>

        <div>
          <Label htmlFor='mark-scheme' title='Mark scheme' />

          <TextEditor.Container>
            <Controller
              name='markScheme'
              control={control}
              rules={{ required: 'Mark scheme is required' }}
              render={({ field }) => (
                <TextEditor
                  uploadImage={uploadImage}
                  id='mark-scheme'
                  {...field}
                />
              )}
            />

            <TextEditor.Actions>
              <TextEditor.ToolbarToggle />
            </TextEditor.Actions>
          </TextEditor.Container>

          <small>
            Provide a mark scheme that includes a model answer, how to award marks etc.
          </small>

          <If condition={errors.markScheme}>
            <p className='mt-1 text-sm font-semibold text-red-500' role='alert'>{errors.markScheme.message}</p>
          </If>
        </div>

        <div className='flex gap-3 justify-end'>
          <Button
            className='ml-auto'
            variant='outlined'
            theme='light'
            type='button'
            disabled={isLoading}
            label={<span className='flex gap-2'><BeakerIcon className='h-5 w-5' /> Preview question</span>}
            onClick={() => setIsTestModalOpen(true)}
          />

          <Button
            type='submit'
            disabled={isLoading}
            label={isLoading ? 'Saving...' : 'Save'}
          />
        </div>
      </form>

      <PreviewFreeResponseQuestionModal
        blockId={id}
        getValues={getValues}
        closeModal={() => setIsTestModalOpen(false)}
        isOpen={isTestModalOpen}
      />
    </>
  )
}

export default FreeResponseQuestionForm
