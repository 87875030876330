import { useForm } from 'react-hook-form'
import { gql } from 'graphql-request'
import { CheckIcon } from '@heroicons/react/24/outline'

import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import Select from '@components/Select'
import TextInput from '@components/TextInput'

const UPDATE_ORGANIZATION_PROFILE = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
        name
        organizationType
      }
      errors {
        message
      }
    }
  }
`

const Profile = ({ organizationId, name, organizationType, refetch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onTouched', defaultValues: { name, organizationType } })

  const { mutate: updateOrganization, isLoading: isUpdating, isSuccess } = useMutation({
    gqlMutation: UPDATE_ORGANIZATION_PROFILE,
    onSuccess: () => refetch()
  })

  const onSubmit = data => updateOrganization({ input: { id: organizationId, ...data } })

  return (

    <Card className='p-5'>
      <h2 className='text-2xl font-bold font-heading'>Profile</h2>

      <form className='flex flex-col mt-5' onSubmit={handleSubmit(onSubmit)}>
        <If condition={errors.general}>
          <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.general.message}</p>
        </If>

        <div>
          <TextInput
            className='w-full mr-5'
            id='name'
            label='Name'
            {...register('name')}
          />

          <If condition={errors.name}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.name.message}</p>
          </If>
        </div>

        <div>
          <Select
            id='organization-type'
            label='Organization type'
            className='w-full'
            {...register('organizationType')}
          >
            <Select.Option value=''>Select answer</Select.Option>
            <Select.Option value='SCHOOL_K12'>School (K12)</Select.Option>
            <Select.Option value='UNIVERSITY'>University</Select.Option>
            <Select.Option value='ONLINE_SCHOOL'>Online school</Select.Option>
            <Select.Option value='MICROSCHOOL'>Microschool</Select.Option>
            <Select.Option value='HOMESCHOOL'>Homeschool</Select.Option>
            <Select.Option value='TUTOR_PROGRAMME'>Tutor programme</Select.Option>
            <Select.Option value='NGO'>NGO</Select.Option>
            <Select.Option value='OTHER'>Other</Select.Option>
          </Select>

          <If condition={errors.organizationType}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.organizationType.message}</p>
          </If>
        </div>

        <div className='ml-auto'>
          <If condition={isSuccess}>
            <CheckIcon className='mr-2 inline-block h-5 w-5' />
          </If>

          <Button
            className='mt-5 w-fit'
            theme='primary'
            disabled={isUpdating}
            type='submit'
            label={isUpdating ? 'Saving...' : 'Save'}
          />
        </div>
      </form>
    </Card>
  )
}

export default Profile
