import { Tab } from '@headlessui/react'
import { gql } from 'graphql-request'
import {
  ShieldCheckIcon,
  IdentificationIcon,
  EnvelopeIcon,
  CreditCardIcon,
  ArrowsRightLeftIcon,
  UserCircleIcon
} from '@heroicons/react/24/outline'

import { useQuery } from '@hooks/graphql'
import PageHeading from '@components/PageHeading'

import Account from './Account'
import Profile from './Profile'
import EmailPreferences from './EmailPreferences'
import SecuritySettings from './SecuritySettings'
import Billing from './Billing'
import Integrations from './Integrations'
import Spinner from '../../../components/Spinner'

const ME_QUERY = gql`
  query me {
    me {
      profile: newProfile {
        firstName
        lastName
        biography
        googleClassroomIntegrationEnabled
        microsoftTeamsIntegrationEnabled
        subscribedToEmailMarketing
        profileImage {
          url
        }
      }
    }
  }
`

const Settings = () => {
  const { isLoading, refetch, data: { me: { profile = {} } = {} } = {} } = useQuery({
    queryKey: ['settings'],
    gqlQuery: ME_QUERY
  })

  return (
    <>
      <PageHeading title='Settings' />

      <div className='h-full'>
        <Tab.Group as='div' className='flex flex-col md:flex-row md:h-full'>
          <Tab.List className='flex flex-col text-base p-5 space-y-1 border-gray-200 border-r h-full min-w-[250px]'>
            <Tab className='ui-selected:bg-gray-200 ui-selected:font-semibold hover:text-blue-600 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 leading-6 outline-none'>
              <IdentificationIcon className='h-5 w-5 inline-block mr-2' />
              Account
            </Tab>

            <Tab className='ui-selected:bg-gray-200 ui-selected:font-semibold hover:text-blue-600 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 leading-6 outline-none'>
              <UserCircleIcon className='h-5 w-5 inline-block mr-2' />
              Profile
            </Tab>

            <Tab className='ui-selected:bg-gray-200 ui-selected:font-semibold hover:text-blue-600 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 leading-6 outline-none'>
              <ShieldCheckIcon className='h-5 w-5 inline-block mr-2' />
              Security settings
            </Tab>

            <Tab className='ui-selected:bg-gray-200 ui-selected:font-semibold hover:text-blue-600 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 leading-6 outline-none'>
              <EnvelopeIcon className='h-5 w-5 inline-block mr-2' />
              Email preferences
            </Tab>

            <Tab className='ui-selected:bg-gray-200 ui-selected:font-semibold hover:text-blue-600 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 leading-6 outline-none'>
              <ArrowsRightLeftIcon className='h-5 w-5 inline-block mr-2' />
              Integrations
            </Tab>

            <Tab className='ui-selected:bg-gray-200 ui-selected:font-semibold hover:text-blue-600 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 leading-6 outline-none'>
              <CreditCardIcon className='h-5 w-5 inline-block mr-2' />
              Billing
            </Tab>
          </Tab.List>

          <Choose>
            <When condition={isLoading}>
              <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-full' />
            </When>

            <Otherwise>
              <Tab.Panels className='flex-grow md:p-5'>
                <Tab.Panel>
                  <Account />
                </Tab.Panel>

                <Tab.Panel>
                  <Profile
                    firstName={profile.firstName}
                    lastName={profile.lastName}
                    biography={profile.biography}
                    profileImageUrl={profile.profileImage?.url}
                    refetch={refetch}
                  />
                </Tab.Panel>

                <Tab.Panel>
                  <SecuritySettings />
                </Tab.Panel>

                <Tab.Panel>
                  <EmailPreferences
                    subscribedToEmailMarketing={profile.subscribedToEmailMarketing}
                    refetch={refetch}
                  />
                </Tab.Panel>

                <Tab.Panel>
                  <Integrations
                    googleClassroomIntegrationEnabled={profile.googleClassroomIntegrationEnabled}
                    microsoftTeamsIntegrationEnabled={profile.microsoftTeamsIntegrationEnabled}
                    refetch={refetch}
                  />
                </Tab.Panel>

                <Tab.Panel>
                  <Billing />
                </Tab.Panel>
              </Tab.Panels>
            </Otherwise>
          </Choose>
        </Tab.Group>
      </div>
    </>
  )
}

export default Settings
