import { useState, useEffect } from 'react'
import { gql } from 'graphql-request'
import { CloudArrowDownIcon } from '@heroicons/react/24/outline'

import { useQuery } from '@hooks/graphql'
import { formatDuration, formatShortDuration } from '@helpers/format'
import LinkButton from '@components/LinkButton'
import Card from '@components/Card'
import Spinner from '@components/Spinner'
import { BarChart, ScatterChart } from '@components/Charts'

import ActiveSessionNoInsights from './ActiveSessionNoInsights'
import ClosedSessionNoInsights from './ClosedSessionNoInsights'
import CalculatingInsights from './CalculatingInsights'
import InsightCard from './InsightCard'
import InsightTable from './InsightTable'
import InsightExpandableList from './InsightExpandableList'

import { capitalize, orderBy, round } from 'lodash'
import jsonToCsvExport from 'json-to-csv-export'

const CLASSROOM_INSIGHTS_QUERY = gql`
  query classroomInsights($classroomId: ID!, $insightableId: ID!) {
    classroomInsights(classroomId: $classroomId, insightableId: $insightableId) {
      profile {
        firstName
        lastName
      }
      totalTimeSeconds
      activeTimeSeconds
      questionsAsked
      questionsAnswered
      answerQuality
      group
      misconceptionsConcepts
      misconceptionsDetails
      misconceptionsSyllabusConnection
      misconceptionsRootCauses
      misconceptionsWorthReteaching
    }
  }
`

const Insights = ({ assignmentId, assignmentClosed, assignmentClosedAt, classroomId }) => {
  const [isCalculating, setIsCalculating] = useState(false)
  const { isLoading, refetch, data: { classroomInsights: rawData = [] } = {} } = useQuery({
    queryKey: ['classroomInsights', classroomId, assignmentId],
    gqlQuery: CLASSROOM_INSIGHTS_QUERY,
    variables: { classroomId, insightableId: assignmentId },
    enabled: !!classroomId && !!assignmentId
  })

  useEffect(() => {
    // If calculating, refetch the data every 30 seconds for 5 minutes.
    // If data is available, the user will see the insights.
    // Otherwise, the user will see the "No insights available" message after 5 minutes.
    const recentlyClosed = () => assignmentClosed && assignmentClosedAt && new Date(assignmentClosedAt) > new Date(Date.now() - 5 * 60 * 1000)

    if (!recentlyClosed()) {
      setIsCalculating(false)
      return
    }

    setIsCalculating(true)

    const interval = setInterval(() => {
      if (recentlyClosed()) {
        refetch()
      } else {
        refetch()
        setIsCalculating(false)
        clearInterval(interval)
      }
    }, 30000)
  }, [assignmentClosed, assignmentClosedAt, setIsCalculating, refetch])

  const scatterData = rawData.reduce((acc, student) => {
    const key = student.answerQuality
    const value = {
      y: student.questionsAsked,
      x: round(student.activeTimeSeconds / 60, 2),
      tooltipData: {
        Name: `${student.profile.firstName} ${student.profile.lastName}`,
        Questions: student.questionsAsked,
        Quality: capitalize(student.answerQuality),
        Time: formatDuration(student.activeTimeSeconds)
      }
    }

    if (!acc[key]) {
      acc[key] = []
    }

    acc[key].push(value)
    return acc
  }, {})

  const calculateAverage = (data, key) => {
    const total = data.reduce((acc, student) => acc + (student[key] || 0), 0)
    return total / data.length
  }

  const classAverages = {
    title: 'Class Averages',
    data: [
      { name: 'Total Time', value: formatShortDuration(calculateAverage(rawData, 'totalTimeSeconds')) },
      { name: 'Active Engagement', value: formatShortDuration(calculateAverage(rawData, 'activeTimeSeconds')) },
      { name: 'Questions Asked', value: calculateAverage(rawData, 'questionsAsked').toFixed(0) },
      { name: 'Questions Answered', value: calculateAverage(rawData, 'questionsAnswered').toFixed(0) }
    ]
  }

  const misconceptions = {
    title: 'Misconceptions',
    data: orderBy(
      rawData.reduce((acc, student) => {
        if (!student.misconceptionsConcepts) return acc

        student.misconceptionsConcepts.forEach((misconception) => {
          const existingMisconception = acc.find(item => item.Topic === misconception)
          if (existingMisconception) {
            existingMisconception.Frequency += 1
          } else {
            acc.push({ Topic: misconception, Frequency: 1 })
          }
        })
        return acc
      }, []),
      ['Frequency'],
      ['desc']
    )
  }

  const rootCauses = {
    title: 'Root Causes',
    type: 'table',
    data: rawData.reduce((acc, student) => {
      if (!student.misconceptionsRootCauses) return acc

      student.misconceptionsRootCauses.forEach((rootCauseList) => {
        rootCauseList.split(',').forEach((rootCause) => {
          const trimmedRootCause = rootCause.trim()
          if (!acc.some(item => item.Topic === trimmedRootCause)) {
            acc.push({ Topic: trimmedRootCause })
          }
        })
      })
      return acc
    }, [])
  }

  const worthReteaching = {
    title: 'Topics Worth Reteaching',
    type: 'table',
    data: rawData.reduce((acc, student) => {
      if (!student.misconceptionsConcepts || !student.misconceptionsWorthReteaching) return acc

      student.misconceptionsConcepts.forEach((misconception, index) => {
        if (student.misconceptionsWorthReteaching[index] === 'Yes') {
          if (!acc.some(item => item.Topic === misconception)) {
            acc.push({ Topic: misconception })
          }
        }
      })
      return acc
    }, [])
  }

  const activeEngagement = {
    title: 'Active Engagement Time',
    type: 'barChart',
    options: {
      labels: {
        x: 'Student',
        y: 'Active Time (mins)'
      }
    },
    data: orderBy(
      rawData.map((student) => ({
        value: round(student.activeTimeSeconds / 60, 2),
        tooltipData: { Name: `${student.profile.firstName} ${student.profile.lastName}`, Time: formatDuration(student.activeTimeSeconds) }
      })),
      ['name'],
      ['asc']
    )
  }

  const studentEngagement = {
    title: 'Student Engagement',
    type: 'scatterChart',
    options: {
      labels: {
        x: 'Active Time',
        y: 'Questions Asked'
      },
      xAxis: {
        unit: 'min'
      },
      fill: {
        null: '#64748b',
        poor: '#ef4444',
        satisfactory: '#eab308',
        good: '#22c55e'
      }
    },
    data: scatterData
  }

  const misconceptionsExamples = {
    title: 'Common Misconceptions',
    type: 'expandableList',
    data: orderBy(
      rawData.reduce((acc, student) => {
        if (!student.misconceptionsConcepts) return acc

        student.misconceptionsConcepts.forEach((misconception, index) => {
          const existingMisconception = acc.find(item => item.name === misconception)
          if (existingMisconception) {
            existingMisconception.count += 1
          } else {
            acc.push({
              name: misconception,
              count: 1,
              details: student.misconceptionsDetails[index] || 'No details available.'
            })
          }
        })
        return acc
      }, []),
      ['count'],
      ['desc']
    )
  }

  const handleDataExport = () => {
    const dataToExport = rawData.map(insightData => {
      const { profile, ...rest } = insightData

      return {
        studentName: `${profile.firstName} ${profile.lastName}`,
        ...rest
      }
    })

    jsonToCsvExport({ data: dataToExport, filename: 'mindjoy-export' })
  }

  return (
    <>
      <Choose>
        <When condition={isLoading}>
          <Spinner className='flex items-center justify-center w-full mt-32' />
        </When>

        <When condition={rawData.length === 0}>
          <Choose>
            <When condition={isCalculating}>
              <CalculatingInsights />
            </When>

            <When condition={assignmentClosed}>
              <ClosedSessionNoInsights />
            </When>

            <Otherwise>
              <ActiveSessionNoInsights />
            </Otherwise>
          </Choose>
        </When>

        <Otherwise>
          <If condition={assignmentClosed && rawData?.length > 0}>
            <div className='flex justify-end items-center pt-3'>
              <LinkButton onClick={() => handleDataExport()}>
                <span className='flex items-center gap-2'>
                  Export insights
                  <CloudArrowDownIcon className='size-6' />
                </span>
              </LinkButton>
            </div>
          </If>

          <div className='my-6 space-y-10'>
            <div className='grid space-y-3'>
              <h3 className='text-lg font-semibold text-gray-900'>{classAverages.title}</h3>

              <InsightCard data={classAverages.data} />
            </div>

            <div className='grid space-y-3'>
              <h3 className='text-lg font-semibold text-gray-900'>{misconceptions.title}</h3>

              <InsightTable data={misconceptions.data} />
            </div>

            {/* TODO(raoul): Make misconceptions table row expandable so we can show the examples */}
            <div className='grid space-y-3'>
              <InsightExpandableList data={misconceptionsExamples.data} />
            </div>

            <div className='grid space-y-3'>
              <h3 className='text-lg font-semibold text-gray-900'>{rootCauses.title}</h3>

              <InsightTable data={rootCauses.data} />
            </div>

            <div className='grid space-y-3'>
              <h3 className='text-lg font-semibold text-gray-900'>{worthReteaching.title}</h3>

              <InsightTable data={worthReteaching.data} />
            </div>

            <div className='grid space-y-3'>
              <h3 className='text-lg font-semibold text-gray-900'>{activeEngagement.title}</h3>

              <Card className='grid space-y-3 items-center p-6'>
                <BarChart
                  data={activeEngagement.data}
                  options={activeEngagement.options}
                />
              </Card>
            </div>

            <div className='grid space-y-3'>
              <h3 className='text-lg font-semibold text-gray-900'>{studentEngagement.title}</h3>

              <Card className='grid space-y-3 items-center p-6'>
                <ScatterChart
                  data={studentEngagement.data}
                  options={studentEngagement.options}
                />
              </Card>
            </div>
          </div>
        </Otherwise>
      </Choose>
    </>
  )
}

export default Insights
