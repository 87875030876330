import { useForm } from 'react-hook-form'
import { gql } from 'graphql-request'
import { BoltIcon } from '@heroicons/react/24/solid'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { useMutation, useQuery } from '@hooks/graphql'
import { useFlashMessage } from '@components/FlashMessage'
import LinkButton from '@components/LinkButton'
import Button from '@components/Button'
import Checkbox from '@components/Checkbox'
import Modal from '@components/Modal'

import { useSubscription } from '../../hooks/subscription'
import ProFeatureTooltip from '../../components/ProFeatureTooltip'

const ASSIGNMENTS_QUERY = gql`
  query series($id: ID!) {
    node(id: $id) {
      ... on Series {
        assignments {
          id
          createdAt
          closed
          classroom {
            id
            name
          }
        }
      }
    }
  }
`

const CLASSROOMS_QUERY = gql`
  query classrooms {
    classrooms {
      id
      name
    }
  }
`

const ASSIGN_CLASSROOMS_TO_SERIES_MUTATION = gql`
  mutation assignClassroomsToSeries($input: AssignClassroomsToSeriesInput!) {
    assignClassroomsToSeries(input: $input) {
      series {
        id
        classrooms {
          id
          name
        }
      }
    }
  }
`

const UPDATE_ASSIGNMENT_MUTATION = gql`
  mutation updateAssignment($input: UpdateAssignmentInput!) {
    updateAssignment(input: $input) {
      assignment {
        id
      }
    }
  }
`

const AssignClassroomsModal = ({
  closeModal,
  isOpen,
  id,
  name
}) => {
  const { setFlashMessage } = useFlashMessage()
  const { hasProFeatures } = useSubscription()

  const { data: { classrooms: educatorClassrooms = [] } = {} } = useQuery({
    queryKey: ['assignSeriesClassrooms'],
    gqlQuery: CLASSROOMS_QUERY
  })

  const { data: { node: { assignments = [] } = {} } = {}, refetch: refetchAssignments } = useQuery({
    queryKey: ['seriesAssignments', id],
    gqlQuery: ASSIGNMENTS_QUERY,
    variables: { id }
  })

  const { mutate: updateAssignment } = useMutation({
    gqlMutation: UPDATE_ASSIGNMENT_MUTATION,
    onSuccess: () => refetchAssignments()
  })

  const { mutate: assignClassrooms, isLoading: isAssigning } = useMutation({
    gqlMutation: ASSIGN_CLASSROOMS_TO_SERIES_MUTATION,
    variables: { input: { seriesId: id } },
    onSuccess: () => {
      setFlashMessage('Pathway assigned', 'success')
      closeModal()
      refetchAssignments()
    }
  })

  const { handleSubmit, register, formState: { isDirty } } = useForm({
    defaultValues: { classroomIds: [] }
  })

  const sortClassrooms = (classrooms, assignments) => {
    const { withAssignments, withoutAssignments } = classrooms.reduce((acc, classroom) => {
      const hasAssignment = assignments.some(assignment => assignment.classroom.id === classroom.id)

      if (hasAssignment) {
        acc.withAssignments.push(classroom)
      } else {
        acc.withoutAssignments.push(classroom)
      }

      return acc
    }, { withAssignments: [], withoutAssignments: [] })

    return [...withAssignments, ...withoutAssignments]
  }

  const sortedClassrooms = sortClassrooms(educatorClassrooms, assignments)

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
    >

      <h3 className='font-heading text-2xl font-bold mb-3'>Assign</h3>
      <p className='mb-3'>You're about to assign <strong>{name}</strong>.</p>

      <form
        onSubmit={handleSubmit(data => assignClassrooms({ input: { seriesId: id, ...data } }))}
        className='flex flex-col mt-3'
      >
        <ul className='w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg'>
          <For each='classroom' of={sortedClassrooms}>
            <li key={classroom.id} className='flex items-center w-full border-b border-gray-200 rounded-t-lg'>
              <With assignment={assignments.find(assignment => assignment.classroom.id === classroom.id)}>
                <Choose>
                  <When condition={assignment}>
                    <Choose>
                      <When condition={assignment.closed}>
                        <LockClosedIcon className='ml-3 size-5 text-red-600' />
                      </When>

                      <Otherwise>
                        <BoltIcon className='ml-3 size-5 text-green-600' />
                      </Otherwise>
                    </Choose>
                    <Link
                      to={`/educators/classrooms/${classroom.id}/courses/${id}`}
                      className='py-3 ml-5 text-sm font-medium text-gray-900 hover:underline hover:text-blue-600'
                    >
                      {classroom.name}
                    </Link>

                    <Choose>
                      <When condition={assignment.closed}>
                        <LinkButton
                          type='button'
                          onClick={() => updateAssignment({ input: { assignmentId: assignment.id, closed: false } })}
                          className='pr-3 ml-auto'
                        >
                          <span className='flex items-center'>Reopen</span>
                        </LinkButton>
                      </When>

                      <Otherwise>
                        <LinkButton
                          type='button'
                          onClick={() => updateAssignment({ input: { assignmentId: assignment.id, closed: true } })}
                          className='text-red-500 pr-3 ml-auto hover:text-red-600'
                        >
                          <span className='flex items-center'>Close</span>
                        </LinkButton>
                      </Otherwise>
                    </Choose>
                  </When>

                  <Otherwise>
                    <Checkbox
                      {...register('classroomIds')}
                      value={classroom.id}
                      id={classroom.id}
                      label={classroom.name}
                      className='flex items-center ps-3'
                      labelClassName='w-full py-3 ms-2 text-sm font-medium text-gray-900'
                    />
                  </Otherwise>
                </Choose>
              </With>
            </li>
          </For>
        </ul>

        <Button
          id='assign-button'
          disabled={isAssigning || !isDirty || !hasProFeatures}
          label={isAssigning ? 'Assigning...' : 'Assign'}
          type='submit'
          className='w-fit mt-5 self-end'
        />

        <If condition={!hasProFeatures}>
          <ProFeatureTooltip anchorSelect='#assign-button' />
        </If>
      </form>
    </Modal>
  )
}

export default AssignClassroomsModal
