import { gql } from 'graphql-request'
import { differenceInDays } from 'date-fns'

import { useQuery } from '@hooks/graphql'

const SUBSCRIPTION_QUERY = gql`
  query subscription {
    organization {
      verificationStatus
      subscription {
        startedAt
        expiresAt
        cancelledAt
      }
    }
    me {
      profile: newProfile {
        trialStartedAt
        trialLength
        totalStudentsInClassroomsCount
        subscription {
          plan
          startedAt
          expiresAt
          cancelledAt
        }
      }
    }
  }
`

const useSubscription = () => {
  const {
    isLoading,
    data: {
      me: { profile: { subscription, trialStartedAt, trialLength, totalStudentsInClassroomsCount } = {} } = {},
      organization: { subscription: organizationSubscription, verificationStatus } = {}
    } = {}
  } = useQuery({
    queryKey: ['subscription'],
    gqlQuery: SUBSCRIPTION_QUERY,
    staleTime: 86400000 // Only refetch once a day
  })

  const trialLengthInDays = trialLength || 14
  const daysInTrial = differenceInDays(new Date(), new Date(trialStartedAt))
  const daysLeftInTrial = trialLengthInDays - daysInTrial
  const hasActiveSubscription = !!subscription || !!organizationSubscription
  const isWithinTrialPeriod = !hasActiveSubscription && daysLeftInTrial > 0

  const hasProFeatures = !!subscription || !!organizationSubscription || isWithinTrialPeriod

  return {
    isLoading,
    isWithinTrialPeriod,
    daysLeftInTrial,
    hasActiveSubscription,
    subscription,
    organizationSubscription,
    verificationStatus,
    hasProFeatures,
    totalStudentsInClassroomsCount
  }
}

export {
  useSubscription
}
