import { gql } from 'graphql-request'
import { useForm } from 'react-hook-form'

import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import Modal from '@components/Modal'
import TextInput from '@components/TextInput'
import PasswordInput from '@components/PasswordInput'
import { useFlashMessage } from '@components/FlashMessage'

const UPDATE_STUDENT_PROFILE_MUTATION = gql`
  mutation updateStudent($input: UpdateStudentInput!) {
    updateStudent(input: $input) {
      profile {
        firstName
        lastName
        email
      }
      errors {
        message
      }
    }
  }
`

const EditStudentProfileModal = ({ id, firstName, lastName, email, onClose, isOpen, refetch }) => {
  const { setFlashMessage } = useFlashMessage()
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstName,
      lastName,
      email
    }
  })
  const { mutate: updateStudent, isLoading: sending } = useMutation({
    gqlMutation: UPDATE_STUDENT_PROFILE_MUTATION,
    variables: { input: { firstName, lastName, email, profileId: id } },
    onSuccess: () => {
      refetch()
      setFlashMessage('Student updated', 'success')
      onClose()
      reset()
    }
  })

  const submit = ({ firstName, lastName, email, password }) => {
    updateStudent({
      input: {
        firstName,
        lastName,
        email,
        profileId: id,
        ...password ? { password } : {}
      }
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        onSubmit={handleSubmit(submit)}
        className='flex flex-col space-y-4 mt-5'
      >
        <h2 className='font-heading text-3xl font-bold'>Edit student</h2>

        <div className='flex w-full gap-3 [&_*]:w-full '>
          <TextInput
            id='first-name'
            label='First name'
            {...register('firstName')}
          />

          <TextInput
            id='last-name'
            label='Last name'
            {...register('lastName')}
          />
        </div>

        <div>
          <TextInput
            id='email'
            label='Email'
            type='email'
            {...register('email', { pattern: { value: /(.+)@(.+){2,}\.(.+){2,}/i, message: 'Email must be valid' } })}
          />
          <If condition={errors.email}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.email.message}</p>
          </If>
        </div>

        <div>
          <PasswordInput
            id='password'
            label='Password'
            {...register('password', { required: false, minLength: { value: 8, message: 'Password must be at least 8 characters' } })}
          />
          <If condition={errors.password}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.password.message}</p>
          </If>

          <small className='text-sm'>Changing a student's password will sign them out of their account.</small>
        </div>

        <Button
          disabled={sending}
          className='w-fit ml-auto'
          type='submit'
          label={sending ? 'Saving...' : 'Save'}
        />
      </form>
    </Modal>
  )
}

export default EditStudentProfileModal
