import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'
import { truncate } from 'lodash'
import { BuildingLibraryIcon, GlobeAltIcon, LockClosedIcon } from '@heroicons/react/24/outline'

import { useQuery, useMutation } from '@hooks/graphql'
import { formatShortTimestamp } from '@helpers/format'
import Spinner from '@components/Spinner'
import Button from '@components/Button'
import Table from '@components/Table'
import Pagination from '@components/Pagination'
import Modal from '@components/Modal'
import TextInput from '@components/TextInput'
import TextArea from '@components/TextArea'
import RadioGroup from '@components/RadioGroup'

import { useSubscription } from '../../hooks/subscription'
import ProFeatureTooltip from '../../components/ProFeatureTooltip'

const CREATE_SERIES_MUTATION = gql`
  mutation createSeries($input: CreateSeriesInput!) {
    createSeries(input: $input) {
      series {
        id
        name
        description
        createdAt
      }
    }
  }
`

const SERIES_QUERY = gql`
  query mySeries($page: Int) {
    mySeries(page: $page, perPage: 10) {
      nodes {
        id
        name
        createdAt
        description
        visibility
        coverImageThumbnail {
          url
        }
        profile {
          id
          fullName
        }
        organization {
          id
          name
        }
        educatorProjects {
          id
          name
        }
      }
      pagesCount
      nodesCount
    }
  }
`

const VisiblilityBadge = ({ visibility }) => (
  <div className='flex flex-row items-center gap-1 w-fit px-2 py-1 rounded-3xl bg-gray-100 leading-none'>
    <Choose>
      <When condition={visibility === 'PUBLIC'}>
        <GlobeAltIcon className='w-4 h-4' />
        Public
      </When>

      <When condition={visibility === 'PRIVATE'}>
        <LockClosedIcon className='w-4 h-4 ' />
        Private
      </When>

      <When condition={visibility === 'ORGANIZATION'}>
        <BuildingLibraryIcon className='w-4 h-4 ' />
        Organization
      </When>
    </Choose>
  </div>
)

const initials = name => {
  if (!name) return ''

  return name.split(' ').map(word => word[0]).join('')
}

const MyLibrary = () => {
  const navigate = useNavigate()
  const { hasProFeatures } = useSubscription()
  const [page, setPage] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { register, handleSubmit, formState: { errors }, control } = useForm({ mode: 'onTouched', defaultValues: { visibility: 'PRIVATE' } })

  const { isLoading, data: { mySeries: { nodes: series = [], nodesCount, pagesCount } = {} } = {} } = useQuery({
    queryKey: ['myLibrarySeries', page],
    gqlQuery: SERIES_QUERY,
    variables: { page },
    keepPreviousData: true
  })
  const { mutate: createSeries, isLoading: isCreating } = useMutation({
    gqlMutation: CREATE_SERIES_MUTATION,
    onSuccess: data => navigate(`/educators/courses/${data.createSeries.series.id}`)
  })

  const onSubmit = data => createSeries({ input: data })

  return (
    <>
      <Choose>
        <When condition={isLoading}>
          <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />
        </When>

        <Otherwise>
          <div className='flex flex-col md:px-5 mt-3'>
            <Button
              id='new-pathway-button'
              disabled={!hasProFeatures}
              size='sm'
              className='mb-3 ml-auto mr-3 md:mr-0'
              onClick={() => setIsModalOpen(true)}
              label='New pathway'
            />

            <If condition={!hasProFeatures}>
              <ProFeatureTooltip anchorSelect='#new-pathway-button' />
            </If>

            <div className='overflow-x-scroll p-[1px]'>
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header width='10%' />
                    <Table.Header width='30%'>Name</Table.Header>
                    <Table.Header width='40%'>Description</Table.Header>
                    <Table.Header width='10%'>Created at</Table.Header>
                    <Table.Header width='10%'>Visibility</Table.Header>
                  </Table.Row>
                </Table.Head>

                <Table.Body>
                  <If condition={series.length < 1}>
                    <Table.Row>
                      <Table.Cell colSpan='5'>You don't have any series yet, go ahead and create one 😀</Table.Cell>
                    </Table.Row>
                  </If>

                  <For each='s' of={series}>
                    <Table.Row key={s.id}>
                      <Table.Cell>
                        <Choose>
                          <When condition={s.coverImageThumbnail?.url}>
                            <img src={s.coverImageThumbnail.url} className='size-10 rounded-md' />
                          </When>

                          <Otherwise>
                            <span className='inline-flex shrink-0 size-10 items-center justify-center rounded-md bg-blue-100'>
                              <span className='font-medium leading-none text-blue-400'>{initials(s?.name)}</span>
                            </span>
                          </Otherwise>
                        </Choose>
                      </Table.Cell>
                      <Table.Cell>
                        <Link className='block text-gray-900' to={`/educators/courses/${s.id}`}>
                          <Choose>
                            <When condition={s.name}>
                              {s.name}
                            </When>
                            <Otherwise>
                              Untitled pathway
                            </Otherwise>
                          </Choose>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link className='block' to={`/educators/courses/${s.id}`}>{truncate(s.description, { length: 70 })}</Link>
                      </Table.Cell>
                      <Table.Cell><Link className='block' to={`/educators/courses/${s.id}`}>{formatShortTimestamp(s.createdAt)}</Link></Table.Cell>
                      <Table.Cell>
                        <Link className='block' to={`/educators/courses/${s.id}`}>
                          <VisiblilityBadge visibility={s.visibility} />
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  </For>
                </Table.Body>
              </Table>
            </div>

            <Pagination page={page} pagesCount={pagesCount} setPage={setPage} />
          </div>
        </Otherwise>
      </Choose>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size='sm'
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col'
        >
          <h3 className='text-2xl font-semibold mb-3'>New pathway</h3>
          <div className='mb-3'>
            <TextInput
              id='name'
              label='Name'
              required
              {...register('name', { required: 'Name is required' })}
            />
            <If condition={errors.name}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.name.message}</p>
            </If>
          </div>

          <div className='mb-5'>
            <TextArea
              id='description'
              label='Description'
              required={false}
              {...register('description')}
            />
            <If condition={errors.description}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.description.message}</p>
            </If>
          </div>

          <div className='mb-5'>
            <Controller
              name='visibility'
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  theme='primary'
                  label='Visibility'
                >
                  <RadioGroup.Option
                    label={
                      <span className='flex items-center'>
                        <LockClosedIcon className='h-5 w- mr-2' />
                        Private
                      </span>
                    }
                    description='This pathway will only be visible to you.'
                    value='PRIVATE'
                  />
                </RadioGroup>
              )}
            />
          </div>

          <Button
            disabled={isCreating}
            type='submit'
            label={isCreating ? 'Creating...' : 'Create pathway'}
          />
        </form>
      </Modal>
    </>
  )
}

export default MyLibrary
