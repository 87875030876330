import Card from '@components/Card'

const NoResults = ({ status }) => (
  <Card className='w-auto flex flex-col items-center justify-center p-10 m-5'>
    <img src='/reading.svg' alt='Student reading' className='w-[120px] self-center mb-5' />

    <Choose>
      <When condition={status === 'open'}>
        <h4 className='text-2xl font-semibold'>No pathways assigned</h4>
        <p>You don't have any pathways assigned to you (just yet!)</p>
      </When>

      <Otherwise>
        <h4 className='text-2xl font-semibold'>No pathways</h4>
        <p>You don't have any completed or closed pathways</p>
      </Otherwise>
    </Choose>
  </Card>
)

export default NoResults
